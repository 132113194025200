.agent_form {
  position: relative;
  /* text-align: center; */
  top: 60px;
  margin-bottom: 100px;

  .form_main {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 4, 51, 0.2);
    padding: 20px;

    .form_head {
      font-size: 32px;
      font-weight: 500;
      height: auto;
      letter-spacing: 0.1px;
      line-height: 40px;

      margin: 0 24px 24px 0px;
    }

    .check {
      display: flex;
      flex-direction: row;
      gap: 37px;
      margin-top: 16px;

      @media screen and (max-width: 444px) {
        flex-direction: column;

        .checkbox1 {
          margin-left: 30px;
        }
      }
    }

    .eabout {
      color: #000433;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.1px;
      line-height: 24px;
      min-width: 100px;
      .back_to_result {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        a {
          text-decoration: none;
        }

        :hover {
          color: #0499a8;
        }
      }
    }

    .popertyadd {
      color: #000433;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.1px;
      line-height: 24px;
    }

    .form-control {
      background-color: #fff;
      border: 1px solid #888a9a;
      border-radius: 8px;
      box-sizing: border-box;
      color: #000433;
      font-family: Effra, sans-serif;
      font-size: 16px;
      font-weight: 400;
      height: 48px;
      letter-spacing: 0.1px;
      line-height: 24px;
      // padding: 8px 16px;
      width: 100%;

      &:focus {
        border: 2px solid #014deb;
        outline: none;
        -webkit-transition-duration: 0.16s;
        transition-duration: 0.16s;
        box-shadow: none !important;
      }
    }

    .form-check-label {
      margin: 0px 10px;
    }

    select {
      border: 1px solid #888a9a;
      border-radius: 8px;
      color: #000433;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.1px;
      line-height: 24px;
      margin: 0;
      outline: 0;
      padding: 8px 40px 8px 16px;
      width: 100%;
      height: 65px;
    }

    .submit {
      -webkit-tap-highlight-color: transparent;
      border-radius: 8px;
      border-style: solid;
      border-width: 2px;
      color: #000433;
      cursor: pointer;
      font-family: Effra, sans-serif;
      font-size: 16px;
      font-weight: 400;
      font-weight: 500;
      letter-spacing: 0.1px;
      line-height: 24px;
      min-width: 96px;
      -webkit-transition-duration: 0.24s;
      transition-duration: 0.24s;
      height: 48px;
      padding: 0 16px;
      // background-color: #00deb6;
      background-color: #fee600;
      color: #000433;
      width: 100%;
      border: none;

      &:hover {
        background-color: transparent;
        border: 2px solid #fee600;
      }
    }

    label {
      font-size: 16px;
      line-height: 24px;
      width: 100%;
      font-family: Effra, sans-serif;
      font-weight: 400;
      letter-spacing: 0.1px;
    }

    .form-check-input {
      border: 1px solid black;
      height: 20px;
      width: 20px;

      &:checked {
        background-color: black !important;
      }

      &:focus {
        box-shadow: none;
      }
    }

    .char {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.1px;
      line-height: 20px;
      margin-top: 8px;
    }
  }

  .right_card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 6px #a1a1ac;
    flex-direction: column;
    margin: 0 auto;
    max-width: 425px;

    .card_price {
      background-color: #f4f4f5;
      padding: 16px 24px;

      > div {
        color: #0499a8;
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 0.1px;
        line-height: 32px;
      }
    }

    .card_main_content {
      font-size: 14px;
      font-weight: 400;
      font-weight: 500;
      letter-spacing: 0.1px;
      line-height: 20px;
      padding: 24px 24px 0px;
      .prop_data {
        align-items: center;
        color: #000433;
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.1px;
        line-height: 24px;
      }

      .card_add {
        color: #6c6d7f;
        display: -webkit-box;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.1px;
        line-height: 24px;
        margin-bottom: 3px;
        margin-top: 8px;
        max-height: 50px;
        overflow: hidden;
      }
    }

    .card_hl {
      border-top: 1px solid #e9e9eb;
      margin: 8px 24px;
    }

    .marketed_by {
      padding: 0 24px 24px;

      .img_marketing {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.1px;
        line-height: 20px;
        margin: auto 0;
        min-width: 104px;

        .marketing_logo {
          border: 1px solid #dfdfe1;
          border-radius: 5px;
          max-height: 50px;
          max-width: 96px;
        }
      }

      .company_name {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.1px;
        line-height: 24px;
      }

      .company_address {
        color: #4f5064;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.1px;
        line-height: 24px;
        margin-top: 8px;
      }
    }
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.mail_confirmation {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

$color_1: #9a9da0;

body {
  margin-top: 20px;
}

.card-box {
  .card-drop {
    color: $color_1;
    font-size: 20px;
    line-height: 1px;
    padding: 0px 5px;
    display: inline-block;
  }
}

.text-muted {
  color: $color_1 !important;
}
