.Footer_wrapper {
  width: 100%;
  // padding-left: 250px;
  // padding-right: 250px;
  // padding-top: 130px;
  // padding-bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  background-color: #f5f5f5;

  .Footer_inner_wrapper {
    margin: 100px;
    height: auto;
    text-align: center;

    @media screen and (max-width: 818px) {
      margin-left: -81px;
      margin-right: -76px;
      // border: 2px solid red;
    }
    @media screen and (max-width: 653px) {
      margin-left: -11px;
      margin-right: -16px;
      // border: 2px solid red;
    }
  }

  @media screen and (min-width: 500px) and (max-width: 820px) {
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 70px;
    padding-bottom: 70px;
    // margin-top: 140px;
  }

  @media screen and (min-width: 100px) and (max-width: 500px) {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 50px;
    padding-bottom: 50px;
    // margin-top: 150px;
  }

  img {
    @keyframes shake1 {
      0% {
        // transform: ;
        transform: rotate(-10deg);
      }

      5% {
        transform: scale(1.1);
        transform: rotate(10deg);
      }

      10% {
        transform: scale(1.2);
        transform: rotate(-10deg);
      }

      15% {
        transform: scale(1.2);
        transform: rotate(10deg);
      }

      20% {
        transform: scale(1.3);
        transform: rotate(0deg);
      }

      100% {
        transform: scale(1.3);
        transform: rotate(0deg);
      }
    }

    // width: 200px;
    // height: 200px;
    &.active4 {
      animation: mymove3 2s;

      &:hover {
        width: 70px !important;
        animation: shake1 0.7s ease;
      }
    }

    &.active3 {
      animation: mymove3 2s;

      &:hover {
        width: 50px !important;
        animation: shake1 0.7s ease;
      }
    }
  }

  h1 {
    color: #011e60;
    font-family: "Museo Slab 700";
  }
}

@keyframes active1 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.activate1 {
  transform: 0.3s;
  // animation: fadeIn 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
  animation: active1 3s ease 0s 1 normal forwards;
}
.footerpart {
  // border:2px solid red ;
  background-color: #f5f5f5;
  // margin-top: 120px;
  
  .copyRights {
    display: flex;
    justify-content: space-between;
    color: #807f7f;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    position: relative;
    bottom: -25px;
    background-color: #f5f5f5;
    padding-bottom: 10px;

    @media screen and(max-width:818px) {
      flex-direction: column !important;
      align-items: center;
      bottom: -50px;
      padding-bottom: 10px;
    }

    p {
      svg {
        font-size: 15px;
      }
    }
  }
}
