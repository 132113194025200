#PrivacyPolice {
    // border:2px solid green;
    margin-top: 71px;

    // -----Global--------
    .Titles {
        color: #000;
        font-weight: 800;
        padding-right: 4px;
    }

    .PrivacyWrap {
        padding-top: 52px;
        text-align: justify;
    }

    // ---------Content---part---------------------
    #ContentPart {

        margin-top: 20px;

        //   ------------Listed----Data-----------------
        .inner-Listed {
            padding: 20px;
        }
    }

    .custom-oders {
        border: 1px solid black;
        padding-left: 0px !important;
        padding-right: 0px !important;

        .custom-oder {

            padding-top: 10px;
            // height: 489px;
            border-bottom: 1px solid black;
            min-height: 310px;

        }

        .innerROW {
            margin-left: 0px !important;
            margin-right: 0px !important;
        }

        .InnerCol {
            color: #fff;
            // padding: 7px;
            background-color: rgb(196, 196, 196);
            width: 100%;
            height: 53px;
            display: flex;
            align-items: center;
            padding-left: 12px;
            justify-content: flex-start;

        }

    }
}

.Listed {
    padding-left: 29px;
}