.cont1 {
  position: relative;
  top: 55px;
  margin-bottom: 40px;

  @media screen and (min-width: 701px) and (max-width: 1100px) {
    top: 50px !important;
  }

  @media screen and (max-width: 700px) {
    top: 50px !important;
  }

  .projects_background {
    background-image: url("../../assets/images/large/boomerang-projects-flash.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    // height: 637px;
    height: 640px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .project-text {
      // color: white;
      font-family: "Roboto Slab", serif !important;
      font-size: 40px;
      position: relative;
      text-align: end;
      padding-right: 12%;
      // background-color: rgba(0, 0, 0, 0.5);
      background-color: #fff;
      color: #011e60;
      padding: 50px;
      // margin-right: 50px;
      animation: go 1s;
      text-transform: uppercase;
      font-weight: 700 !important;

      @keyframes go {
        from {
          transition: 0.5s ease-in-out;
          opacity: 0;
          // left: 100%;
        }

        to {
          transition: 0.5s ease-in-out;
          opacity: 1;
          // left: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .cont1 {
    padding: 0%;
  }
}

@media screen and (min-width: 1168px) and (max-width: 1256px) {
  .cont1 .projects_background {
  }
}

@media screen and (min-width: 1022px) and (max-width: 1100px) {
  .cont1 {
    .projects_background {
      height: 500px;
    }
  }

  // .cont1 .projects_background .project-text {
  //   // padding-right: 17%;
  //   top: 114px;
  // }
}

@media screen and (min-width: 900px) and (max-width: 1022px) {
  .cont1 .projects_background {
    height: 500px;
  }

  // .cont1 .projects_background .project-text {
  //   padding-right: 15%;
  //   top: 161px;
  // }
}

@media screen and (min-width: 778px) and (max-width: 899px) {
  .cont1 .projects_background {
    height: 500px;
  }

  // .cont1 .projects_background .project-text {
  //   padding-right: 12%;
  //   top: 138px;
  // }
}

@media screen and (min-width: 712px) and (max-width: 777px) {
  .cont1 .projects_background {
    height: 400px;
  }

  // .cont1 .projects_background .project-text {
  //   padding-right: 9%;
  //   top: 120px;
  // }
}

@media screen and (min-width: 630px) and (max-width: 711px) {
  .cont1 .projects_background {
    height: 400px;
  }

  // .cont1 .projects_background .project-text {
  //   padding-right: 6%;
  //   top: 102px;
  // }
  .cont1 .projects_background .project-text {
    font: 30px "Museo Slab 700";
  }
}

@media screen and (min-width: 500px) and (max-width: 629px) {
  .cont1 .projects_background {
    height: 370px;
  }

  // .cont1 .projects_background .project-text {
  //   padding-right: 6%;
  //   top: 102px;
  // }
  .cont1 .projects_background .project-text {
    font: 30px "Museo Slab 700";
  }
}

@media screen and (min-width: 400px) and (max-width: 499px) {
  .cont1 .projects_background {
    height: 300px;
  }

  // .cont1 .projects_background .project-text {
  //   padding-right: 4%;
  //   top: 110px;
  // }
  .cont1 .projects_background .project-text {
    font: 20px "Museo Slab 700";
  }
}

@media screen and (min-width: 200px) and (max-width: 399px) {
  .cont1 .projects_background {
    height: 240px;
  }

  // .cont1 .projects_background .project-text {
  //   padding-right: 3%;
  //   top: 60px;
  // }
  .cont1 .projects_background .project-text {
    font: 20px "Museo Slab 700";
  }
}
