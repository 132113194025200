@import "~react-image-gallery/styles/scss/image-gallery.scss";

.property_full {
  position: relative;
  text-align: center;
  top: 85px;
  margin-bottom: 100px;

  .header {
    float: left;
    text-align: left;
    display: flex;
    color: #11828d;
    cursor: pointer;
    text-decoration: none;
    cursor: pointer;
    z-index: 2;
    position: relative;
    margin: 15px;
    width: 97%;
  }

  .main_container {
    width: 100%;
    position: relative;
    .back_to_results {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      a {
        text-decoration: none;
      }

      :hover {
        color: #0499a8;
      }
    }

    .image {
    }

    .carousel {
      position: relative;
      margin: 12px;

      .outside-page-btn {
        font-size: 15px;
        border: 1px solid;
        background: white;
        color: black;
        bottom: 8px;
        left: 88%;
        width: 7%;
        line-height: 1;
        padding: 10px 15px;
        position: absolute;
        white-space: normal;
        z-index: 999;
        border-radius: 7px;

        span{
          
        }

        @media screen and (min-width: 290px) and (max-width: 360px) {
          left: 60%;
          padding: 10px 10px;
        }

        @media screen and (min-width: 360px) and (max-width: 490px) {
          left: 69%;
          padding: 10px 10px;
        }

        @media screen and (min-width: 490px) and (max-width: 650px) {
          left: 76%;
        }

        @media screen and (min-width: 650px) and (max-width: 992px) {
          left: 83%;
        }

        .cameraicon {
          color: #000;
          background-color: white;
          height: 30px;
          width: 30px;
          margin-right: 5px;
        }
      }
    }

    .main_body_content {
      width: 100%;
      padding: 12px;
      background-color: #fff;

      .details1 {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .vl {
          background-color: #e8e8eb;
          height: 24px;
          width: 1px;
          margin: 0 8px;
        }

        .prop_name {
          font-family: Effra-Medium, Tahoma, sans-serif;

          font-weight: normal;
          font-size: 25px;

          // @media screen and (max-width: 500px) {
          //   font-size: 13px;
          //   font-weight: 600;
          // }
        }

        .seemap {
          font-size: 20px;
          font-weight: 700;
          color: #107a84;
          margin: 0;
          cursor: pointer;

          @media screen and (max-width: 500px) {
            font-size: 13px;
          }
        }

        .locmark {
          fill: #107a84;
          margin-right: 8px;
          font-size: 25px;
        }

        .vl2 {
          background-color: #dedee2;
          height: 24px;
          width: 1px;
          margin: 0 8px;
        }
      }

      .details2 {
        text-align: left;
        border-bottom: 1px solid #dedee2;

        .price {
          font-size: 25px;
          margin: 0;
          font-family: Effra-Medium, Tahoma, sans-serif;
          font-weight: 600;

          // @media screen and (max-width: 500px) {
          //   font-size: 16px !important;
          // }
        }

        .parent_inner {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 10px;
          @media screen and (max-width: 500px) {
            // font-size: 13px !important;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
          }
          .monthly_pay {
            color: #107a84;
            font-weight: 600;

            > p {
              font-size: 14px;

              // @media screen and (max-width: 500px) {
              //   font-size: 13px !important;
              // }
            }
          }

          .addedon {
            color: black;

            // @media screen and (max-width: 500px) {
            //   font-size: 13px !important;
            // }
          }
        }
      }

      .details3 {
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 5px;
        flex-wrap: wrap;
        width: 100%;
        border-bottom: 1px solid #dedee2;
        padding: 8px 0;
        color: #6c6d7f;
        font-size: 12px;
        // text-align: left;

        @media screen and (max-width: 500px) {
          font-size: 9px !important;
        }
      }
      .image_container1 {
        height: auto;
        // flex-wrap: wrap;
        margin-top: 0;
        display: flex;
        width: 100%;
        margin: auto;
        cursor: pointer;
        overflow: auto;

        @media screen and (max-width: 750px) {
          flex-wrap: nowrap;
          width: 100%;
          overflow: auto;
          margin-top: 20px;
        }

        .img_count {
          color: #4f5064;
          background-color: #f2f3f4;
          border-radius: 4px;
          width: 100px;
          height: 100px;
          font-family: Effra-Medium, Tahoma, sans-serif;
          font-size: 20px;
          font-family: Effra-Medium, Tahoma, sans-serif;
          text-align: center;
          line-height: 95px;
          display: inline-block;
          vertical-align: top;

          .plus-count-img {
            border: 1px solid greenyellow;
          }
        }
      }
      .image_container {
        height: auto;
        flex-wrap: wrap;
        margin-top: 0;
        display: flex;
        width: 100%;
        margin: auto;
        cursor: pointer;

        @media screen and (max-width: 750px) {
          // flex-wrap: nowrap;
          // width: 100%;
          overflow: auto;
          margin-top: 20px;
          justify-content: center;
        }

        .img_count {
          color: #4f5064;
          background-color: #f2f3f4;
          border-radius: 4px;
          width: 100px;
          height: 100px;
          font-family: Effra-Medium, Tahoma, sans-serif;
          font-size: 20px;
          font-family: Effra-Medium, Tahoma, sans-serif;
          text-align: center;
          line-height: 95px;
          display: inline-block;
          vertical-align: top;

          .plus-count-img {
            // border: 1px solid greenyellow;
            height: 90%;
            width: 90%;
            padding: 5px;
          }
          .plus-count-no {
            position: absolute;
            font-size: 28px;
            font-weight: 500;
          }
        }
      }

      .key_features {
        width: 100%;
        margin-top: 20px;
        // height: 201px;

        .key_header {
          font-size: 20px;
          text-align: left;
          font-weight: 600;
        }

        .key_list {
          width: 50%;
          text-align: left;
          // display: inline-block;
          display: inline-flex;

          padding: 0 16px;
          // float: left;
          margin-bottom: 16px;

          @media screen and (max-width: 600px) {
            width: 100%;
          }
        }

        .key_list::before {
          background-color: #151f3a;
          border-radius: 100%;
          content: "";
          display: inline-block;
          height: 8px;
          width: 8px;
          top: 8px;
          left: 0;
          margin-right: 10px;
          position: relative;
          padding: 4px;
        }
      }

      .discription {
        width: 100%;
        margin-top: 20px;

        .header_discription {
          font-size: 20px;
          text-align: left;
          font-weight: 600;
        }

        .text {
          text-align: left;
        }
      }

      .map_view {
        width: 100%;
        // height: 500px;
        margin-top: 70px;

        .map_main {
          width: 100%;
          height: 100%;
          border: 1px solid #dedee2;

          // .leaflet-container {
          //   height: 100%;
          //   width: 94%;
          //   margin: 26px;
          //   position: absolute !important;
          //   @media screen and (max-width: 1000px) {
          //     margin: 14px;

          //   }
          //   @media screen and (max-width: 750px) {
          //     margin: 7px;

          //   }
          //   @media screen and (max-width: 580px) {
          //     margin: 3px;

          //   }

          //   @media screen and (max-width: 400px) {

          //   height: 43%;
          //   width: 89%;
          //   margin: 4px;
          //   position: absolute !important;
          //   }
          // }
          .leaflet-container {
            position: relative;
            margin-top: 0%;
            height: 499px !important;
            width: 100% !important;
          }

          .leaflet-marker-pane img {
            background: transparent !important;
          }

          .MapContainerCss {
            display: flex;
            justify-content: center;
          }

          @media only screen and (min-width: 0px) and (max-width: 600px) {
            .footer {
              position: absolute;
              margin-top: 10%;
            }
          }
        }

        .tags {
          border: 1px solid #dedee2;
          width: 100%;

          .tagheader {
            border-bottom: 1px solid #dedee2;
            width: 100%;
            height: 60px;

            .station {
              float: left;
              width: 150px;
              height: 100%;
              text-align: center;
              padding: 16px;
              font-size: 17px;
              border: navajowhite;
              background: #fff;
              font-weight: 400;
              margin-right: 10px;
              display: flex;
              justify-content: center;
              align-items: baseline;

              @media screen and (max-width: 380px) {
                width: 100px;
              }

              // &:hover {
              //   border-bottom: 5px solid #107a84;
              //   color: #107a84;

              // }
            }

            .school {
              float: left;
              width: 150px;
              height: 100%;
              text-align: center;
              padding: 16px;
              font-size: 17px;
              border: navajowhite;
              background: #fff;
              font-weight: 400;
              display: flex;
              justify-content: center;
              align-items: baseline;

              @media screen and (max-width: 380px) {
                width: 100px;
              }

              // &:hover {
              //   border-bottom: 5px solid #107a84;
              //   color: #107a84;
              // }
            }

            .activetag {
              border-bottom: 5px solid #107a84;
              color: #107a84;
            }
          }
          .parent_st_sc {
            overflow: auto;
            // min-width: 700px;

            .neareststation {
              font-size: 15px;
              color: rgb(79, 80, 100);
              letter-spacing: 5px;
              text-align: left;
              margin: 15px;
              font-weight: 400;
              min-width: 700px;

              @media screen and (max-width: 380px) {
                font-size: 13px;
              }
            }

            .map_content {
              text-align: left;
              /* display: flex; */
              padding: 17px;
              font-size: 20px;
              min-width: 700px;

              .text_content {
                display: flex;

                .station_name {
                  color: #000433;
                  font-size: 14px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  width: 200px;
                }

                .station_miles {
                  color: #4f5064;
                  font-size: 14px;
                  flex: 0 0 auto;
                  padding-left: 16px;
                }

                .school_content {
                  display: flex;
                  flex-direction: column;
                  margin-bottom: 12px;
                  // width: 75%;
                  width: 20%;

                  > p {
                    margin: 0;
                    color: #107a84;
                    font-size: 14px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    margin-bottom: 4px;

                    @media screen and (max-width: 450px) {
                      font-size: 12px;
                    }
                  }

                  > div {
                    > span {
                      padding-right: 8px;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      color: #4f5064;
                      font-size: 12px;

                      @media screen and (max-width: 450px) {
                        font-size: 10px;
                      }
                    }
                  }
                }

                .miles {
                  margin-left: 75px;
                  color: #4f5064;
                  font-size: 14px;
                  flex: 0 0 auto;
                  padding-left: 16px;
                  // text-align: left;
                  width: 15%;

                  @media screen and (max-width: 480px) {
                    padding-left: 0px;
                    margin-left: 35px;
                  }

                  @media screen and (max-width: 450px) {
                    font-size: 12px;
                  }
                }

                > p {
                  margin-right: 50px;
                }
              }
            }
          }
        }
      }

      .project-table {
        margin-top: 55px;
        background-color: white;

        .table-main {
          table th:last-of-type,
          table td:last-of-type,
          table th:nth-of-type(2),
          table th:nth-of-type(3),
          table td:nth-of-type(2),
          table td:nth-of-type(3) {
            text-align: right;
            // background-color: brown !important;
          }

          .table-heading {
            // border: 1px solid;
            margin-left: -20px;

            .heading-name {
              font-size: 20px;
              font-weight: 700;
              text-align: left;
            }
          }

          table {
            th {
              color: #fff;
              padding: 0.5rem;
              white-space: nowrap;
              text-align: left;
              // border-right: 1px solid #fff;
            }

            td {
              height: 30px;
              padding: 0.5rem;
              white-space: nowrap;
              text-align: left;
              // border-right: 1px solid #fff;
            }
          }

          table {
            font: 15px "Museo Slab 300";
            // margin-bottom: 6.5rem;
            border-collapse: collapse;
            border-spacing: 0;

            thead tr {
              background: #176574 !important;
            }
          }

          table tbody tr:nth-child(odd) {
            background: #f6f6f6;
          }

          table tbody tr:nth-child(even) {
            background: #f6f6f6;
          }
        }

        .blueprint {
          margin-top: -90px;

          .carousel-control-next,
          .carousel-control-prev {
            top: 136px;
          }

          .carousel-control-next,
          .carousel-control-prev {
            width: 10%;
            height: 5%;
          }

          .carousel-control-prev-icon {
            background-image: url(../assets/images/large/arrow_L.png);
            width: 22px;
            height: 22px;
          }

          .carousel-control-next-icon {
            background-image: url(../assets/images/large/arrow_R.png);
            width: 22px;
            height: 22px;
          }

          .text-center {
            text-align: left !important;
          }

          img {
            width: 91%;
            height: 70%;
            padding-left: 52px;
          }
        }
      }

      ///
      .agent {
        z-index: 1;
        width: inherit;
        background-color: #fff;
        border-radius: 11px;
        box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
        padding: 24px;
        position: relative;
        width: 100%;

        .agent_details {
          display: flex;
          justify-content: space-between;
          margin-top: 8px;
        }
      }
    }
  }
}

.prev_prop {
  background-image: linear-gradient(-90deg, transparent, #262637);
  top: auto;
  height: 465px;
  display: none;
  border-radius: 10px 0px 0px 10px;
  @media screen and(max-width:450px) {
    height: 265px;
  }
  // @media screen and (max-width: 700px) {
  //   top: 137px;
  // }
}

.next_prop {
  background-image: linear-gradient(90deg, transparent, #262637);
  height: 465px;
  // top: 182px;
  top: auto;

  display: none;
  border-radius: 0px 10px 10px 0px;

  @media screen and(max-width:450px) {
    height: 265px;
  }

  // @media screen and (max-width: 700px) {
  //   top: 137px;
  // }
}

.carousel-inner .carousel-item {
  transition: -webkit-transform 2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.gallery {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
  // background-color: rgba(0, 0, 0, 1);
  background-color: white;


  .image-gallery {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin-top: 10px;
    background-color: black;
  }

  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh - 124px);
    padding: 50px;
    background: white;
  }

  .image-gallery-slide .image-gallery-description {
    // background: rgba(0, 0, 0, 0.4);
    background: none;
    // bottom: 70px;
    // color: #fff;
    bottom: 8px;
    color: #000;
    left: 50%;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    white-space: normal;
  }

  .image-gallery-icon {
    color: black;
    transition: all 0.3s ease-out;
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    position: absolute;
    z-index: 4;
    filter: drop-shadow(0 2px 2px #1a1a1a);
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.req_details {
  background: #000433;
  padding: 16px;
  // margin-top: 16px;
  border-radius: 4px;
  min-width: 250px;
  max-width: 300px;
  width: 20vw;
  @media screen and (max-width: 500px) {
    width: 100%;
  }

  .call {
    margin-bottom: 10px;
    text-align: left;
    color: #fff;
    font-size: 16px;
    letter-spacing: 1px;

    @media screen and (max-width: 850px) {
      font-size: 13px;
    }

    .callagent {
      color: #00deb6;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 10px;

      @media screen and (max-width: 991px) {
        font-size: 13px;
      }
    }
  }

  .req_button {
    justify-content: center;
    text-align: center;
    align-items: center;
    // background: #00deb6;
    background-color: #f8da00;
    font-family: Effra-Medium, Tahoma, sans-serif;
    font-family: Effra-Medium, Tahoma, sans-serif;
    // padding: 8px 12px;
    line-height: 24px;
    border-radius: 4px;
    color: #000433;
    height: 50px;
    width: 100%;
    border: none;

    &:hover {
      background-color: #fff;
      border: 2px solid #f8da00;
      // color: #f8da00;
    }

    // @media screen and (max-width: 850px) {
    //   font-size: 12px;
    // }
  }
}
