.main_contact_wrapper {
  @media screen and (min-width: 701px) and (max-width: 1200px) {
    // top: 74px !important;
    padding: 0%;
    // height: 437px;
  }

  @media screen and (max-width: 700px) {
    // top: 54px !important;
    padding: 0%;
    // height: 337px;
  }
}

.contact-card {
  position: relative;
  width: 100%;
  // top: 35px;
  background-image: url(../assets/images/large/boomerang-contact-flash.jpg);
  background-size: 100% 100%;
  // height: 637px;
  height: 600px;
  background-repeat: no-repeat;
  padding: 0%;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  .prop-banner-innerwrap {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    // border: 1px solid red;
    width: 100%;

    .row {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      // border: 1px solid red;
      width: 101%;

      .left_content {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        // background-color: rgba(0, 0, 0, 0.5);
        width: auto;
        color: #011e60;
        background-color: #fff;
        // margin-right: 20px;
        width: 525px;
        padding: 30px 0 30px 30px;
        // p {
        //   margin: 0;
        //   // padding: 20px 40px;
        //   font-size: 50px;
        // }

        @keyframes leftmove {
          from {
            margin-right: -200px;
          }
          to {
            margin-right: 20px;
          }
        }

        .Header {
          // margin-right: 20px;
          font-size: 40px;
          color: #011e60;
          text-transform: uppercase;
          font-weight: 700;
        }
        @media screen and (max-width: 470px) {
          width: 300px;
          h2 {
            font-size: 22px;
          }
          p {
          }
        }

        ul li::marker {
          color: #011e60; /* Change this to the color you desire */
        }

        
      }

      
    }
  }

  .Inner_Content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    // background-color: rgba(0, 0, 0, 0.5);
    width: auto;
    color: black;
    background-color: #fff;
    // margin-right: 20px;
    width: 525px;
    padding: 30px 0 30px 30px;
    // p {
    //   margin: 0;
    //   // padding: 20px 40px;
    //   font-size: 50px;
    // }

    @keyframes leftmove {
      from {
        margin-right: -200px;
      }
      to {
        margin-right: 20px;
      }
    }

    .Header {
      // margin-right: 20px;
      font-size: 40px;
      color: #011e60;
      text-transform: uppercase;
      font-weight: 700;
    }
    @media screen and (max-width: 470px) {
      width: 300px;
      h2 {
        font-size: 22px;
      }
      p {
      }
    }
  }

  @media screen and (min-width: 701px) and (max-width: 1100px) {
    top: 50px !important;
    padding: 0%;
    height: 437px;
  }

  @media screen and (max-width: 700px) {
    top: 54px !important;
    padding: 0%;
    // height: 337px;
    height: 537px;
  }

  img {
    height: 637px;
    display: none;
  }
}
