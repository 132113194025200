.about_us {
  position: relative;
  top: 50px;

  @media screen and (min-width: 701px) and (max-width: 1100px) {
    top: 50px !important;
    padding: 0%;
  }

  @media screen and (max-width: 700px) {
    top: 50px !important;
    padding: 0%;
  }

  .formdiv {
    background-image: url("../../assets/images/large/about-us-flash.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 637px;
    padding: 0%;

    // display: flex;
    .form_right {
      position: relative;
      // background-color: rgba(0, 0, 0, 0.5);
      background-color: #fff;
      padding: 20px;
      color: black;
      // display: none;
      top: 10%;
      right: 10px;
      left: 23%;
      width: 50%;
      height: auto;
      // animation: leftmove1 0.7s;
      // animation: go1
      p {
        font-size: 16px;
      }

      .form_header {
        // line-height: 61px !important;
        color: #011e60;
        // font: 40px "Museo Slab 700";
        font-weight: 40px;
        font-weight: 700;
        font-size: 40px;
        text-align: center;
        margin-bottom: 2rem;
        display: flex;
        // justify-content: center;
        align-items: center;
        padding: 0;
        width: 100%;
        position: relative;
        top: 10px;
      }

      .form_input {
        .form_main {
          padding-right: 21%;
          padding-left: 6%;

          .input1 {
            padding-bottom: 22px;

            input {
              border-radius: unset !important;
            }
          }

          .input_row2 {
            .input2 {
              input {
                border-radius: unset !important;
              }
            }

            .input3 {
              input {
                border-radius: unset !important;
              }
            }
          }
        }
      }

      .form_footer {
        padding-top: 6%;
        padding-left: 25%;

        button {
          background: transparent;
          border-color: #fff;
          padding: 1rem 4.5rem;
          color: #fff;
          text-transform: uppercase;
          //   border: 1px solid #000;
        }
      }
    }

    @keyframes leftmove1 {
      from {
        left: 0%;
      }

      to {
        left: 23%;
      }
    }
  }

  .text {
    background: white;
    width: 100%;
    position: relative;
    // top: 55%;
    top: 70%;
    height: auto;
    padding-left: 30px;

    .Inner_card_two {
      background-color: white;
      // width: 90%;
      // margin-top: 550px;
      // position: relative;
      // text-align: left;
      // padding-left: 5%;
      // padding-top: 5%;
      // bottom: 386px;
      // padding-bottom: 50px;

      .row {
        width: 100%;

        .card_main {
          // height: 600px;
          height: 750px;

          @media screen and (max-width: 450px) {
            height: 480px;
            margin-bottom: 30px;
          }

          @media screen and (min-width: 250px) and (max-width: 350px) {
            height: 1200px;
            margin-bottom: 30px;
          }
          @media screen and (min-width: 350px) and (max-width: 450px) {
            // border: 1px solid green;
            height: 800px;
            margin-bottom: 30px;
          }
          @media screen and (min-width: 450px) and (max-width: 575px) {
            // border: 1px solid green;
            height: 630px;
            margin-bottom: 30px;
          }

          @media screen and (min-width: 575px) and (max-width: 768px) {
            // border: 1px solid green;
            height: 1050px;
            margin-bottom: 30px;
          }

          @media screen and (min-width: 992px) and (max-width: 1200px) {
            border: 1px solid green;
            height: 1050px;
            margin-bottom: 30px;
          }

          @media screen and (min-width: 1200px) and (max-width: 1400px) {
            // border: 1px solid green;
            height: 850px;
            margin-bottom: 30px;
          }
        }
        .card-body {
          padding: 0 !important;
        }
        .card {
        }

        .innovative_approach,
        .proven_expertise,
        .track_record_of_success,
        .extensive_network,
        .specialist_team,
        .high_income_and_asset,
        .low_risk_propositions,
        .premier_acquisition {
          &:hover {
            transition: 1s;
            transform: scale(1.05);
          }
          img {
            width: 100%;
            height: 100%;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }
          .body {
            img {
              height: 200px;
            }
          }
          .footer {
            background-color: white;
            height: 550px;
            // @media screen and (max-width: 575px) {
            //   height: 480px;
            // }

            @media screen and (min-width: 250px) and (max-width: 350px) {
              height: 1050px;
            }
            @media screen and (min-width: 350px) and (max-width: 450px) {
              // border: 1px solid green;
              height: 750px;
            }

            @media screen and (min-width: 450px) and (max-width: 575px) {
              // border: 1px solid green;
              height: 650px;
            }

            @media screen and (min-width: 575px) and (max-width: 768px) {
              height: 870px;
            }
            @media screen and (min-width: 992px) and (max-width: 1200px) {
              height: 870px;
            }

            @media screen and (min-width: 1200px) and (max-width: 1400px) {
              height: 650px;
            }

            .Border_bottom {
              padding-top: 0px;
            }
          }
        }

        .innovative_approach,
        .proven_expertise,
        .track_record_of_success,
        .extensive_network,
        .specialist_team,
        .high_income_and_asset,
        .low_risk_propositions,
        .premier_acquisition {
          @media screen and (max-width: 992px) {
            margin-top: 0 !important;
            margin-bottom: 20px;
          }
        }

        .proven_expertise {
          .card {
            .body {
            }
          }
        }

        .proven_expertise {
          margin-top: 50px;
        }

        .track_record_of_success {
          margin-top: 150px;
          // padding: 20px;
        }

        .extensive_network {
          margin-top: 250px;
        }

        .specialist_team {
          // margin-top: 350px;

          margin-top: -230px;
        }

        .high_income_and_asset {
          margin-top: -180px;
        }

        .low_risk_propositions {
          // margin-top: -133px;

          margin-top: -82px;
        }

        .premier_acquisition {
          // margin-top: -33px;
          margin-top: 18px;
        }
      }
    }

    ul.special_bullet {
      list-style: none;
    }

    ul.special_bullet li:before {
      color: #000;
      content: "\27bd \0020";
    }

    span {
      font-weight: 600;
    }

    @media screen and (min-width: 700px) and (max-width: 1000px) {
      top: 60%;
    }

    @media screen and (min-width: 200px) and (max-width: 700px) {
      top: 50%;
    }

    h2 {
      padding-top: 40px;
      // font: 20px "Museo Slab 700";
      font-weight: 700;
      font-size: 20px;
      color: #011e60;
    }

    p {
      // font: 17px "Museo Slab 300";
      font-weight: 300;
      font-size: 17px;
      line-height: 30px;
      padding-bottom: 16px;
    }
  }

  .rent-img {
    // background-image: url("../../assets/images/large/rental.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 777px;
    margin-top: -8%;
    background: #ffda00;

    @media screen and (min-width: 701px) and (max-width: 900px) {
      margin-top: -40%;
      padding: 0%;
    }

    @media screen and (min-width: 500px) and (max-width: 700px) {
      margin-top: -86%;
      padding: 0%;
    }

    @media screen and (min-width: 300px) and (max-width: 500px) {
      margin-top: -194%;
      padding: 0%;
    }
  }
}

@media screen and (min-width: 1981px) and (max-width: 2300px) {
  .about_us .text {
    p {
      // font: 30px "Museo Slab 300";
      // font-size: 30px;
    }

    h2 {
      font-size: 30px;
      font-weight: 700;
      // font: 30px "Museo Slab 700";
    }
  }

  .about_us {
    .rent-img {
      margin-top: -0%;
      // height: 1477px;
    }

    .formdiv {
      // height: 1015px;
      .form_right .form_footer {
        padding-left: 32%;
      }
    }
  }
}

@media screen and (min-width: 1440px) and (max-width: 1981px) {
  .about_us .text {
    p {
      // // font: 30px "Museo Slab 300";
      // font-size: 30px;
    }

    h2 {
      font-size: 30px;
      font-weight: 700;
      // font: 30px "Museo Slab 700";
    }
  }

  .about_us {
    .rent-img {
      margin-top: -16%;
      // height: 1177px;
    }

    .formdiv {
      // height: 815px;
      .form_right .form_footer {
        padding-left: 32%;
      }
    }
  }
}

@media screen and (min-width: 1223px) and (max-width: 1439px) {
}

@media screen and (min-width: 1000px) and (max-width: 1222px) {
  .about_us .formdiv .form_right .form_header {
    line-height: 37px !important;
    // font: 31px "Museo Slab 700";
    font-size: 31px;
    font-weight: 700;
  }

  .about_us .text p {
    line-height: 27px;
    padding-bottom: 0px;
  }
}

@media screen and (min-width: 900px) and (max-width: 1000px) {
  .about_us .formdiv .form_right .form_header {
    line-height: 37px !important;
    font-size: 20px;
    font-weight: 700;
    // font: 20px "Museo Slab 700";
  }

  .about_us .text p {
    line-height: 27px;
    padding-bottom: 0px;
  }

  .about_us .formdiv .form_right {
    top: 10%;

    .form_footer {
      padding-left: 33%;

      button {
        padding: 4px 2.5rem;
      }
    }
  }

  .about_us .formdiv {
    background-size: cover;
    height: 392px;

    .form_right .form_input .form_main {
      padding-right: 14%;
      padding-left: 12%;
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 900px) {
  .about_us .formdiv .form_right .form_header {
    line-height: 37px !important;
    // font: 20px "Museo Slab 700";
    font-size: 20px;
    font-weight: 700;
  }

  .about_us .text p {
    line-height: 27px;
    padding-bottom: 0px;
  }

  .about_us .formdiv .form_right {
    top: 10%;

    .form_footer {
      padding-left: 31%;

      button {
        padding: 4px 2.5rem;
      }
    }
  }

  .about_us .formdiv {
    background-size: cover;
    height: 392px;

    .form_right .form_input .form_main {
      padding-right: 14%;
      padding-left: 12%;
    }
  }
}

@media screen and (min-width: 700px) and (max-width: 800px) {
  .about_us .formdiv .form_right .form_header {
    line-height: 26px !important;
    // font: 20px "Museo Slab 700";
    font-size: 20px;
    font-weight: 700;
  }

  .about_us .text {
    p {
      line-height: 27px;
      padding-bottom: 10px;
      // font: 13px "Museo Slab 300";
      font-size: 13px;
      font-weight: 300;
    }

    h2 {
      padding-top: 14px;
      // font: 18px "Museo Slab 700";
      font-size: 18px;
      font-weight: 700;
    }
  }

  .about_us .formdiv .form_right {
    top: 10%;

    .form_footer {
      padding-left: 25%;

      button {
        padding: 4px 2.5rem;
      }
    }
  }

  .about_us .formdiv {
    background-size: cover;
    height: 392px;

    .form_right .form_input .form_main {
      padding-right: 14%;
      padding-left: 12%;
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 700px) {
  .about_us .formdiv .form_right .form_header {
    line-height: 26px !important;
    // font: 20px "Museo Slab 700";
    font-size: 20px;
    font-weight: 700;
  }

  .about_us .text {
    p {
      // line-height: 27px;
      padding-bottom: 10px;
      // font: 13px "Museo Slab 300";
      font-size: 13px;
      font-weight: 300;
    }

    h2 {
      padding-top: 14px;
      // font: 18px "Museo Slab 700";
      font-size: 18px;
      font-weight: 700;
    }
  }

  .about_us .formdiv .form_right {
    top: 8%;

    .form_footer {
      padding-left: 25%;

      button {
        padding: 4px 2.5rem;
      }
    }
  }

  .about_us .formdiv {
    background-size: cover;
    height: 392px;

    .form_right .form_input .form_main {
      padding-right: 14%;
      padding-left: 12%;
    }
  }
}

@media screen and (min-width: 500px) and (max-width: 600px) {
  .about_us .formdiv .form_right {
    left: 0;
    width: 68%;

    .form_header {
      line-height: 26px !important;
      // font: 20px "Museo Slab 700";
      font-size: 20px;
      font-weight: 700;
    }
  }

  .about_us .text {
    p {
      // line-height: 15px;
      padding-bottom: 0px;
      font-size: 13px;
      font-weight: 300;
      // font: 13px "Museo Slab 300";
    }

    p:last-child {
      padding-bottom: 10px;
    }

    h2 {
      padding-top: 14px;
      font-size: 18px;
      font-weight: 700;
      // font: 18px "Museo Slab 700";
    }
  }

  .about_us .formdiv .form_right {
    top: 10%;

    .form_footer {
      padding-left: 30%;

      button {
        padding: 4px 2.5rem;
      }
    }
  }

  .about_us .formdiv {
    background-size: cover;
    height: 392px;

    .form_right .form_input .form_main {
      padding-right: 14%;
      padding-left: 12%;
    }
  }
}

@media screen and (min-width: 450px) and (max-width: 500px) {
  .about_us .formdiv .form_right {
    left: 0;
    width: 100%;

    .form_header {
      line-height: 26px !important;
      font-size: 17px;
      font-weight: 700;
      // font: 17px "Museo Slab 700";
    }
  }

  .about_us .text {
    p {
      // line-height: 15px;
      padding-bottom: 0px;
      font-size: 10px;
      font-weight: 300;
      // font: 10px "Museo Slab 300";
    }

    p:last-child {
      padding-bottom: 10px;
    }

    h2 {
      padding-top: 14px;
      font-size: 15px;
      font-weight: 700;
      // font: 15px "Museo Slab 700";
    }
  }

  .about_us .formdiv .form_right {
    top: 10%;
    left: 10px;
    width: calc(100% - 20px);

    .form_footer {
      padding-left: 32%;

      button {
        padding: 4px 2.5rem;
      }
    }
  }

  .about_us .formdiv {
    background-size: cover;
    height: 332px;

    .form_right .form_input .form_main {
      padding-right: 14%;
      padding-left: 12%;
    }
  }
}

@media screen and (min-width: 400px) and (max-width: 450px) {
  // .about_us .formdiv .form_right {
  //   left: 0;
  //   width: 100%;
  //   .form_header {
  //     // line-height: 26px !important;
  //     // font: 17px "Museo Slab 700";
  //   }
  // }

  // .about_us .text {
  //   p {
  //     // line-height: 15px;
  //     padding-bottom: 0px;
  //     // font: 10px "Museo Slab 300";
  //   }
  //   p:last-child {
  //     padding-bottom: 10px;
  //   }
  //   h2 {
  //     padding-top: 14px;
  //     // font: 15px "Museo Slab 700";
  //   }
  // }
  // .about_us .formdiv .form_right {
  //   top: -8%;
  //   .form_footer {
  //     padding-left: 30%;
  //     button {
  //       padding: 4px 2.5rem;
  //     }
  //   }
  // }
  // .about_us .formdiv {
  //   background-size: cover;
  //   height: 332px;
  //   .form_right .form_input .form_main {
  //     padding-right: 14%;
  //     padding-left: 12%;
  //   }
  // }
  .about_us .formdiv .form_right {
    left: 0;
    width: 100%;

    .form_header {
      // line-height: 22px !important;
      // font: 14px "Museo Slab 700";
      font-size: 14px;
      font-weight: 700;
    }
  }

  .about_us .text {
    p {
      // line-height: 15px;
      padding-bottom: 0px;
      font-size: 10;
      font-weight: 300;

      // font: 8px "Museo Slab 300";
    }

    p:last-child {
      padding-bottom: 10px;
    }

    h2 {
      padding-top: 14px;
      font-size: 13px;
      font-weight: 700;
      // font: 13px "Museo Slab 700";
    }
  }

  .about_us .formdiv .form_right {
    top: 10%;

    .form_footer {
      padding-left: 23%;

      button {
        padding: 4px 2.5rem;
      }
    }
  }

  .about_us .formdiv {
    background-size: cover;
    height: 332px;

    .form_right .form_input .form_main {
      padding-right: 14%;
      padding-left: 12%;
    }
  }
}

@media screen and (min-width: 300px) and (max-width: 400px) {
  .about_us .formdiv .form_right {
    left: 0;
    width: 100%;

    .form_header {
      // line-height: 22px !important;
      // font: 14px "Museo Slab 700";
      font-size: 14px;
      font-weight: 700;
    }
  }

  .about_us .text {
    p {
      // line-height: 15px;
      padding-bottom: 0px;
      // font: 8px "Museo Slab 300";
      font-size: 10;
      font-weight: 300;
    }

    p:last-child {
      padding-bottom: 10px;
    }

    h2 {
      padding-top: 14px;
      // font: 13px "Museo Slab 700";
      font-size: 13px;
      font-weight: 700;
    }
  }

  .about_us .formdiv .form_right {
    top: 10%;

    .form_footer {
      padding-left: 23%;

      button {
        padding: 4px 2.5rem;
      }
    }
  }

  .about_us .formdiv {
    background-size: cover;
    height: 332px;

    .form_right .form_input .form_main {
      padding-right: 14%;
      padding-left: 12%;
    }
  }
}

@media screen and (min-width: 200px) and (max-width: 300px) {
  .about_us .formdiv .form_right {
    left: 0;
    width: 100%;

    .form_header {
      // line-height: 22px !important;
      // font: 14px "Museo Slab 700";
      font-size: 14px;
      font-weight: 700;
    }
  }

  .about_us .text {
    p {
      // line-height: 15px;
      padding-bottom: 0px;
      // font: 8px "Museo Slab 300";
      font-size: 10px;
      font-weight: 300;
    }

    p:last-child {
      padding-bottom: 10px;
    }

    h2 {
      padding-top: 14px;
      // font: 13px "Museo Slab 700";
      font-size: 13px;
      font-weight: 700;
    }
  }

  .about_us .formdiv .form_right {
    top: 10%;

    .form_footer {
      padding-left: 23%;

      button {
        padding: 2px 1.5rem;
      }
    }
  }

  .about_us .formdiv {
    background-size: cover;
    height: 332px;

    .form_right .form_input .form_main {
      padding-right: 14%;
      padding-left: 12%;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1 !important;
  }
}
@keyframes scalCenter {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1 !important;
    transform: scale(1);
  }
}

// .card.animate {
//   // animation: fadeIn 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
//   animation: scalCenter 1s ease 0s 1 normal forwards;
// }
.form_right.animate {
  // animation: fadeIn 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
  animation: scalCenter 1s ease 0s 1 normal forwards;
}
.formdiv.animate {
  animation: fadeIn 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
  // animation: scalCenter 1s ease 0s 1 normal forwards;
}
// .proven_expertise.animate,
// .track_record_of_success.animate,
// .extensive_network.animate,
// .specialist_team.animate,
// .high_income_and_asset.animate,
// .low_risk_propositions.animate,
// .premier_acquisition.animate {
//   animation: fadeIn 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
// }

.activate {
  transform: 0.7s;
  // animation: fadeIn 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
  animation: scalCenter 1s ease 0s 1 normal forwards;
}
