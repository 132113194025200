/* @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap'); */
.App {
  /* text-align: center; */
  /* font-family: ; */
  /* font-family: "Museo Slab 300" !important; */
  /* font-family: Effra,sans-serif !important; */
  /* font-family: 'Roboto Condensed', sans-serif; */
  /* font-family: "MyFont" !important; */
  /* overflow-x: hidden !important; */


}

h1 {
  /* font-family: "MyFont" !important; */
  /* font-weight: 600 !important; */
}

.project-text {
  /* font-family: "MyFont" !important; */

  font-weight: 600 !important;
}

.bottom-container {
  display: flex;
  position: fixed;
  bottom: 0;
  z-index: 777;
}

.bottom-container img {
  margin-left: 10px;
  width: 75px;
  cursor: pointer;
  height: 75px;
  margin-bottom: 75px;
  /* animation: vibrate 0.3s infinite; */
}

.bottom-container .left_side {
  position: fixed;
  right: 10px;
  animation: vibrate1 0.7s infinite;
  visibility: hidden;
  cursor: pointer;
}

.left_side.active {
  visibility: visible;
}

@keyframes vibrate {
  from {
    margin-left: 13px;
  }

  to {
    margin-left: 10px;
  }
}

@keyframes vibrate1 {
  from {
    margin-top: 5px;
  }

  to {
    margin-top: 0px;
  }
}

/* .main-container {
  max-width: 80% !important;
  margin: 0 auto;
} */

@media screen and (max-width: 2000px) {
  .main-container {
    padding: 0 !important;
    margin: 0 !important;
    /* max-width: 90% !important; */
    /* overflow: hidden; */
  }
}
@media screen and (max-width: 720px) {
  .main-container {
    padding: 0 !important;
    margin: 0 !important;
    max-width: 100% !important;
    /* overflow: hidden; */
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.CookieConsent {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  left: 0px;
  position: fixed;
  width: 90% !important;
  background-color:  #151f3a !important;
  z-index: 999;
  bottom: 0px;
  border: 1px solid #ddd !important;
  margin-left: 5% !important;
  margin-bottom: 30px !important;
  font-family: "newPoppins" !important;
 
.Text{
  background-color:  #151f3a;
  /* color: black; */
  color:#fff;
  font-size: 15px;
  }
  span{
  background-color: #151f3a;
  cursor: pointer;
  color:#fff;
  }
 
  div{
  background-color:  #151f3a !important;
  
  }
 
  button{
  background-color:  #151f3a !important;
  padding: 10px !important;
  color: #ffff !important;
  font-size: 13px !important;
  }
 }
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.pointer {
  cursor: pointer;
}
