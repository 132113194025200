.bg-white {
  border: none;
  margin-top: 20px;

  h2 {
    color: #011e60;
  }

  .row {
    width: 100% !important;
  }

  @media screen and (max-width: 767px) {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
  }

  .map_view {
    // width: 100%;
    // height: 500px;
    margin-top: 70px;

    .map_main {
      width: 100%;
      // height: 100%;
      border: 1px solid #dedee2;
      position: relative;
      .pointer {
        position: absolute;
        z-index: 10;
      }

      // .leaflet-container {
      //   height: 100%;
      //   width: 94%;
      //   margin: 26px;
      //   position: absolute !important;
      //   @media screen and (max-width: 1000px) {
      //     margin: 14px;

      //   }
      //   @media screen and (max-width: 750px) {
      //     margin: 7px;

      //   }
      //   @media screen and (max-width: 580px) {
      //     margin: 3px;

      //   }

      //   @media screen and (max-width: 400px) {

      //   height: 43%;
      //   width: 89%;
      //   margin: 4px;
      //   position: absolute !important;
      //   }
      // }
      .leaflet-container {
        position: relative;
        margin-top: 0%;
        height: 599px !important;
        width: 100% !important;
      }

      .leaflet-marker-pane img {
        background: transparent !important;
        margin-left: -73px !important;
        margin-top: -39px !important;
      }

      .MapContainerCss {
        display: flex;
        justify-content: center;
      }
      @media only screen and (min-width: 0px) and (max-width: 600px) {
        .footer {
          position: absolute;
          margin-top: 10%;
        }
      }
    }

    .tags {
      border: 1px solid #dedee2;
      width: 100%;

      .tagheader {
        border-bottom: 1px solid #dedee2;
        width: 100%;
        height: 60px;

        .station {
          float: left;
          width: 150px;
          height: 100%;
          text-align: center;
          padding: 16px;
          font-size: 17px;
          border: navajowhite;
          background: #fff;
          font-weight: 400;
          margin-right: 10px;
          display: flex;
          justify-content: center;
          align-items: baseline;

          @media screen and (max-width: 380px) {
            width: 100px;
          }

          // &:hover {
          //   border-bottom: 5px solid #107a84;
          //   color: #107a84;

          // }
        }

        .school {
          float: left;
          width: 150px;
          height: 100%;
          text-align: center;
          padding: 16px;
          font-size: 17px;
          border: navajowhite;
          background: #fff;
          font-weight: 400;
          display: flex;
          justify-content: center;
          align-items: baseline;

          @media screen and (max-width: 380px) {
            width: 100px;
          }

          // &:hover {
          //   border-bottom: 5px solid #107a84;
          //   color: #107a84;
          // }
        }

        .activetag {
          border-bottom: 5px solid #107a84;
          color: #107a84;
        }
      }

      .neareststation {
        font-size: 15px;
        color: rgb(79, 80, 100);
        letter-spacing: 5px;
        text-align: left;
        margin: 15px;
        font-weight: 400;

        @media screen and (max-width: 380px) {
          font-size: 13px;
        }
      }

      .map_content {
        text-align: left;
        /* display: flex; */
        padding: 17px;
        font-size: 20px;
        overflow: auto;

        .text_content {
          display: flex;

          .station_name {
            color: #000433;
            font-size: 14px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 200px;
          }

          .station_miles {
            color: #4f5064;
            font-size: 14px;
            flex: 0 0 auto;
            padding-left: 16px;
          }

          .school_content {
            display: flex;
            flex-direction: column;
            margin-bottom: 12px;

            > p {
              margin: 0;
              color: #107a84;
              font-size: 14px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-bottom: 4px;

              @media screen and (max-width: 450px) {
                font-size: 12px;
              }
            }

            > div {
              > span {
                padding-right: 8px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                color: #4f5064;
                font-size: 12px;

                @media screen and (max-width: 450px) {
                  font-size: 10px;
                }
              }
            }
          }

          .miles {
            margin-left: 75px;
            color: #4f5064;
            font-size: 14px;
            flex: 0 0 auto;
            padding-left: 16px;

            @media screen and (max-width: 480px) {
              padding-left: 0px;
              margin-left: 35px;
            }

            @media screen and (max-width: 450px) {
              font-size: 12px;
            }
          }

          > p {
            margin-right: 50px;
          }
        }
      }
    }
  }

  a {
    text-decoration: none;
    appearance: none;
    color: black;

    //  line-height: 2.7;
    //  background-repeat: no-repeat left center;

    &.Phoneno {
      background: url(../assets/images/icon-phone.svg) no-repeat left center;
      background-size: 1rem;
      padding-left: 30px;
      margin-bottom: 0.5rem;
    }

    &.mailid {
      background: url(../assets/images/icon-post.svg) no-repeat left center;
      background-size: 1rem;
      padding-left: 30px;
      margin-bottom: 0.5rem;
    }

    &.direction {
      background: url(../assets/images/icon-location.svg) no-repeat left center;
      background-size: contain;
      background-position: 2px;
      padding-left: 30px;
      margin-bottom: 0.5rem;
    }
  }

  .form-control {
    border: 1px solid !important;
    border-radius: 3px !important;
    width: 100% !important;
  }

  .form-select {
    border: 1px solid;
    border-radius: 3px;
  }

  .contactusbutton {
    margin-top: 10px;
    padding: 10px;
    width: 130px;
    background-color: #fee600;
    border-radius: 0.65rem;
    font-weight: 700;
    transition: background-color 0.3s, border 0.3s; /* Add a smooth transition */
    border: 2px solid transparent;

    &:hover {
      background-color: white;
      border: 2px solid #fee600;
    }
  }

  .formdetails {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 800px) {
      display: contents;
    }

    @media screen and (min-width: 768px) and (max-width: 990px) {
      width: 80%;
      // margin-left: 10%;
    }
  }

  //   .contact_wrapper {
  //     display: flex;
  //     justify-content: center;
  //   }

  @media screen and (min-width: 701px) and (max-width: 1100px) {
    margin-top: 180px !important;
    // padding: 0%;
    // height: 437px;
  }

  @media screen and (max-width: 700px) {
    margin-top: 90px !important;
    // padding: 0%;
    // height: 337px;
  }

  .bg-white {
    border: none;
  }

  .card {
    border: none !important;
  }

  .bg-white {
    //  border:1px solid
    width: 100px;
  }

  .logo-contact {
    //
    @media screen and (min-width: 1000px) {
      float: left;
      // margin-top: -20px;
      // display: flex;
      // justify-content: center;
      // align-items: flex-start;
      // margin-top: -137px !important;
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 767px) {
  .bg-white {
    .logo-contact {
      float: left !important;

      //  position: relative !important;
      //  left: 25px !important;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1368px) {
  .bg-white {
    .logo-contact {
      // float: left !important;

      position: relative !important;
      // left: 25px !important;
    }
  }
}

@keyframes contactHero {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.contactHero {
  animation: contactHero 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

.responsive {
  @media screen and (max-width: 767px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
  }
}

.responsive {
  @media screen and (max-width: 767px) {
    top: 20px;
  }
}

.logo-contact {
  @media screen and (max-width: 659px) {
    margin-left: -64px;
  }
}

.responsive2 {
  @media screen and (max-width: 659px) {
    margin-left: 92px;
  }
}

.responsive2 {
  @media screen and (max-width: 330px) {
    margin-left: 30px;
  }
}

// .testing {
//   position: relative;
// }

// .pointer {
//   position: absolute;
//   z-index: 10;
// }
