.Nav_bar {
  // margin: 0px;
  // padding: 0px;
  // padding-left: 5%;
  // padding-right: 5%;
  transition: all 0.5s;
  // background-color: #fff;
  // background-color: #004072;
  // background-color: #151f3a;
  background-color: #FFDA00;

  // background-color: #000433;
  position: fixed;
  z-index: 10001;
  width: 100%;
  top: 0;
  padding-top: 10px;
  box-shadow: 0 0 11px -2px;
  // animation: movetop 1s;
  // position: absolute;

  &.active {
    letter-spacing: 1px;
    font-weight: 500;
    background-color: #151f3a;
    color: white;
    // background-color: #fee600;
    animation: movetop 1s;
  }

  @keyframes movetop {
    from {
      // opacity: 0;
      top: -60px;
    }

    to {
      // opacity: 1;
      top: 0px;
    }
  }

  @media screen and (max-width: 1300px) {
    padding: 0%;
  }

  .Background_fadeout {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: flex-end;
    // transition: all 0.5s;
    z-index: 555;
    left: 0;

    .Mobile_menu {
      width: 40%;
      position: absolute;
      height: 100%;
      background-color: white;
      float: right;
      transition: all 0.5s;

      @media screen and (max-width: 700px) {
        width: 50%;
      }
      @media screen and (max-width: 450px) {
        width: 60%;
      }

      .iconclass {
        margin-top: 40px;
        margin-bottom: 80px;
        float: right;
        margin-right: 40px;
        // width: 30px;

        @media screen and (max-width: 700px) {
          margin-top: 20px;
          margin-bottom: 50px;
        }

        .css-i4bv87-MuiSvgIcon-root {
          width: 40px;
          height: 40px;

          @media screen and (max-width: 700px) {
            width: 25px;
            height: 25px;
          }
        }
      }

      ul {
        margin-left: 22px;
        padding: 0px;
        margin-top: 120px;

        @media screen and (max-width: 700px) {
          margin-top: 80px;
        }

        li {
          text-decoration: none !important;
          margin-top: 30px;
          margin-bottom: 10px;
          appearance: none;
          // text-decoration: none;
          text-align: left;
          list-style-type: none;
          font-size: 19px;

          @media screen and (max-width: 700px) {
            font-size: 15px;
            margin-top: 15px;
          }

          a {
            text-decoration: none;
            appearance: none;
            color: black;
            // color: white;
          }
        }
      }
    }
  }

  .Header {
    width: 90%;
    margin: 0px;
    padding: 0px;
    margin-top: -40px;

    .left_header {
      width: 18%;
      float: left;

      @media screen and (max-width: 1082px) {
        width: 90%;
      }
      

      .img_logo {
        cursor: pointer;
        // background-image: url(../assets/images/boomerang-logo.svg);
        // background-image: url(../assets/images/logoTp.png);
        background-image: url(../assets/images/logoTpBlack.png);
        &.active {
          background-image: url(../assets/images/logoTp.png);
          animation: movetop 1s;
        }

        // background-color: rgba(23, 101, 116, 1);

        // background-size: 100% 100%;
        // background-repeat: no-repeat;
        // width: 130px;
        // height: 112px;
        // position: relative;
        // float: left;
        // margin-left: 40px;
        // margin-right: 25px;

        // background-color: #176574;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 170px;
        height: 60px;
        position: relative;
        float: left;
        /* margin-left: 40px; */
        /* margin-right: 25px; */
        margin: 39px 10px 10px 10px;
        border-radius: 10%;

        @media screen and (max-width: 700px) {
          width: 172px;
          height: 67px;
          margin-left: 10px;
          margin-right: 15px;
        }
      }

      .brand_name {
        color: #176674;
        font-size: 25px;
        text-align: left;
        margin-top: 63px;
        font-family: "Trajan Pro";

        @media screen and (max-width: 700px) {
          margin-top: 63px;
          font-size: 14px;
        }
      }
    }

    .Menu_items {
      width: 80%;
      float: right;
      height: 90px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      z-index: 444;
      position: relative;
      @media screen and (min-width: 1082px) and (max-width: 1114px) {
        // margin-right: 5px;
        font-size: 15px;
      }

      i {
        margin-right: 30px;

        @media screen and (max-width: 300px) {
          margin-right: 5px;
        }
      }

      span {
        margin-right: 60px;

        // &:hover{
        //   a {
        //     text-decoration: none;
        //     appearance: none;
        //     color: gold;
        //   }
        // }

        a {
          text-decoration: none;
          appearance: none;
          color: black;

          &.active {
            // color: #1b2c4f;
            color: white;

            &::after {
              background-color: #176674;
            }
          }

          &::after {
            background-color: goldenrod;
            content: "";
            height: 4px;
            width: 100%;
            display: block;
            transform: scaleX(0);
            transform-origin: left;
            transition: all 0.7s ease;
          }

          &:hover::after {
            transform: scaleX(1);
          }

          &:hover {
            color: #176674;
            // border-bottom: 2px solid goldenrod;
            // transition: all .25s;
            // animation: go .25;
          }

          @keyframes go {
            from {
              left: 0;
            }

            to {
              left: 100%;
            }
          }
        }
      }

      @media screen and (max-width: 1082px) {
        width: 10%;
        justify-content: end;
        align-items: flex-end;
      }

      @media screen and (max-width: 700px) {
        height: 85px;
        align-items: flex-end;
      }
    }
  }

  .backgroundshade {
    height: 760px;
    background-color: #f5f5f5;
    position: absolute;
    margin-top: 112px;
    width: calc(1320px - 193px);
    margin-left: 170px;

    @media screen and (max-width: 1300px) {
      width: calc(100% - 194px);
      // height: 300px;
    }

    @media screen and (min-width: 1100px) and (max-width: 1401px) {
      width: calc(100% - 194px);
      // height: 300px;
    }

    @media screen and (min-width: 700px) and (max-width: 1100px) {
      width: calc(100% - 170px);
      height: 400px;
    }

    @media screen and (max-width: 700px) {
      height: 200px;
      background-color: #f5f5f5;
      position: absolute;
      margin-top: 66px;
      width: calc(100% - 85px);
      margin-left: 85px;
    }
  }
}

//

.drop_menu {
  position: absolute;
  background: white;
  // width: 180px;
  // text-align: center;
  line-height: 32px;
  margin-left: -45px;
  top: 90px;
  border: 1px solid #b1b1b1;
  border-radius: 0px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  padding: 0;
  transition: all 1s ease;
  text-align: left;

  // animation: pulse 1s;

  > li {
    list-style-type: none;
    // border-bottom:1px solid #d0d0d0;
    padding: 10px;

    &:hover {
      background-color: #fff;
    }
  }
}
