.list-view {
  position: relative;
  // margin: 0;
  margin-top: 300px;
  @media screen and  (max-width:600px) {
    margin-top: 500px;
    
  }
  @media screen and  (max-width:400px) {
    margin-top: 630px;
    
  }
  @media screen and  (min-width:760px)and (max-width:840px) {
    margin-top: 500px;
    
  }


  .inside-list {
    max-width: 100%;
    // margin-bottom: 23rem;
    font: 20px "Museo Slab 300";
    img {
      width: 18%;
      height: 29%;
      margin-left: 3%;
      margin-top: 4%;
    }
    ul {
      margin-right: 40%;
      list-style-type: none;
      li {
        border-bottom: 0.1rem solid #ffd200;
        padding: 1.5rem 20% 1.5rem 0;
      }
    }
    ul li:last-child {
      border: 0;
    }
  }
}

// @media screen and (min-width: 1482px) and (max-width: 1981px) {
//   .list-view {
//     margin-top: 160px;
//   }
// }
// @media screen and (min-width: 1281px) and (max-width: 1482px) {
//   .list-view {
//     margin-top: 170px;
//   }
// }
// @media screen and (min-width: 1000px) and (max-width: 1281px) {
//   .list-view {
//     margin-top: 290px;
//   }
// }
// @media screen and (min-width: 835px) and (max-width: 1000px) {
//   .list-view {
//     margin-top: 170px;
//   }
// }
// @media screen and (min-width: 767px) and (max-width: 835px) {
//   .list-view {
//     margin-top: 410px;
//   }
// }
// @media screen and (min-width: 606px) and (max-width: 767px) {
//   .list-view {
//     margin-top: 271px;
//   }
// }
// @media screen and (min-width: 441px) and (max-width: 606px) {
//   .list-view {
//     margin-top: 410px;
//   }
// }
@media screen and (min-width: 408px) and (max-width: 441px) {
  .list-view {
    // margin-top: 400px;
    .inside-list {
      font: 15px "Museo Slab 300";
    }
  }
}
@media screen and (min-width: 365px) and (max-width: 408px) {
  .list-view {
    // margin-top: 480px;
    .inside-list {
      font: 15px "Museo Slab 300";
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 365px) {
  .list-view {
    // margin-top: 270px;
    .inside-list {
      font: 15px "Museo Slab 300";
    }
  }
}
@media screen and (min-width: 200px) and (max-width: 320px) {
  .list-view {
    // margin-top: 270px;
    .inside-list {
      img {
        width: 36%;
        height: 30%;
      }
      font: 11px "Museo Slab 300";
    }
  }
  .project-container
    .houses_main
    .project-row
    .project-col2
    .project-table
    .table-main
    table
    td {
    height: 10px;
  }
}
