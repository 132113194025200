.project-container {
  // padding: 0;
  // margin: 0;

  position: relative;
  top: 251px;

  @media screen and (min-width: 701px) and (max-width: 1100px) {
    top: 250px !important;
  }

  @media screen and (max-width: 700px) {
    top: 134px !important;
  }
  @media screen and (max-width: 600px) {
    top: 240px !important;
  }


  .button-hold {
    display: flex;
    justify-content: flex-start;
    height: 390px !important;

    button {
      border: 1px solid #000;
      background: #fff;
      font: 20px "Museo Slab 300";
      padding: 1rem 4rem;
      margin: 4rem auto;
      display: block;
      cursor: pointer;
      text-transform: uppercase;
      margin-top: 15%;
      color: black;
    }
  }

  .houses_main1 {
    background-image: url("../../assets/images/large/bg-BarkingRoad.jpg");
    background-size: cover;
    background-position: left bottom;
    background-repeat: no-repeat;
    width: 100%;
    height: 205vh;

    .project-row {
      .project-col1 {
      }

      .project-col2 {
        .carousel_top {
          background-color: white;
          margin-top: -322px;

          .carousel-item.active,
          .carousel-item-next,
          .carousel-item-prev {
            display: block;
          }

          .carousel-item.active {
            h3 {
              font: 30px "Museo Slab 700";
              margin: 0;
            }

            .project-text {
              margin-bottom: 2rem;
              margin-top: 7rem;
              text-align: left;

              p {
                font: 18px "Museo Slab 300";
                line-height: 27px;
                padding-bottom: 10px;
              }
            }
          }

          .carousel-control-prev-icon {
            background-image: url(../../assets/images/large/arrow_L.png);
            width: 22px;
            height: 22px;
          }

          .carousel-control-next-icon {
            background-image: url(../../assets/images/large/arrow_R.png);
            width: 22px;
            height: 22px;
          }

          .carousel-control-next,
          .carousel-control-prev {
            height: 5%;
            top: 25px;
          }
        }

        .project-table {
          margin-top: 55px;
          padding-left: 6%;
          padding-top: 9%;
          background-color: white;

          .table-main {
            table th:last-of-type,
            table td:last-of-type {
              text-align: right;
            }

            table {
              th {
                color: #fff;
                padding: 0.5rem;
                white-space: nowrap;
                text-align: left;
                border-right: 1px solid #fff;
              }

              td {
                height: 30px;
                padding: 0.5rem;
                white-space: nowrap;
                text-align: left;
                border-right: 1px solid #fff;
              }
            }

            table {
              font: 15px "Museo Slab 300";
              margin-bottom: 6.5rem;
              border-collapse: collapse;
              border-spacing: 0;

              thead tr {
                background: #4472c8 !important;
              }
            }

            table tbody tr:nth-child(odd) {
              background: #ccd3e9;
            }

            table tbody tr:nth-child(even) {
              background: #e8ecf5;
            }
          }

          .blueprint {
            margin-top: -90px;

            .carousel-control-next,
            .carousel-control-prev {
              top: 136px;
            }

            .carousel-control-next,
            .carousel-control-prev {
              width: 10%;
              height: 5%;
            }

            .carousel-control-prev-icon {
              background-image: url(../../assets/images/large/arrow_L.png);
              width: 22px;
              height: 22px;
            }

            .carousel-control-next-icon {
              background-image: url(../../assets/images/large/arrow_R.png);
              width: 22px;
              height: 22px;
            }

            .text-center {
              text-align: left !important;
            }

            img {
              width: 91%;
              height: 70%;
              padding-left: 52px;
            }
          }
        }
      }
    }
  }

  //two
  .houses_main2 {
    background-image: url("../../assets/images/large/bg-FieldRoad.jpg");
    background-size: cover;
    background-position: left bottom;
    background-repeat: no-repeat;
    width: 100%;
    height: 205vh;

    .project-row {
      .project-col1 {
      }

      .project-col2 {
        .carousel_top {
          background-color: white;
          margin-top: -322px;

          .carousel-item.active,
          .carousel-item-next,
          .carousel-item-prev {
            display: block;
          }

          .carousel-item.active {
            h3 {
              font: 30px "Museo Slab 700";
              margin: 0;
            }

            .project-text {
              margin-bottom: 2rem;
              margin-top: 7rem;
              text-align: left;

              p {
                font: 18px "Museo Slab 300";
                line-height: 27px;
                padding-bottom: 10px;
              }
            }
          }

          .carousel-control-prev-icon {
            background-image: url(../../assets/images/large/arrow_L.png);
            width: 22px;
            height: 22px;
          }

          .carousel-control-next-icon {
            background-image: url(../../assets/images/large/arrow_R.png);
            width: 22px;
            height: 22px;
          }

          .carousel-control-next,
          .carousel-control-prev {
            height: 5%;
            top: 25px;
          }
        }

        .project-table {
          margin-top: 55px;
          padding-left: 6%;
          padding-top: 9%;
          background-color: white;

          .table-main {
            table th:last-of-type,
            table td:last-of-type {
              text-align: right;
            }

            table {
              th {
                color: #fff;
                padding: 0.5rem;
                white-space: nowrap;
                text-align: left;
                border-right: 1px solid #fff;
              }

              td {
                height: 30px;
                padding: 0.5rem;
                white-space: nowrap;
                text-align: left;
                border-right: 1px solid #fff;
              }
            }

            table {
              font: 15px "Museo Slab 300";
              margin-bottom: 6.5rem;
              border-collapse: collapse;
              border-spacing: 0;

              thead tr {
                background: #4472c8 !important;
              }
            }

            table tbody tr:nth-child(odd) {
              background: #ccd3e9;
            }

            table tbody tr:nth-child(even) {
              background: #e8ecf5;
            }
          }

          .blueprint {
            margin-top: -90px;

            .carousel-control-next,
            .carousel-control-prev {
              top: 136px;
            }

            .carousel-control-next,
            .carousel-control-prev {
              width: 10%;
              height: 5%;
            }

            .carousel-control-prev-icon {
              background-image: url(../../assets/images/large/arrow_L.png);
              width: 22px;
              height: 22px;
            }

            .carousel-control-next-icon {
              background-image: url(../../assets/images/large/arrow_R.png);
              width: 22px;
              height: 22px;
            }

            .text-center {
              text-align: left !important;
            }

            img {
              width: 91%;
              height: 70%;
              padding-left: 52px;
            }
          }
        }
      }
    }
  }

  // three
  .houses_main3 {
    background-image: url("../../assets/images/large/bg-KatherineRoad.jpg");
    background-size: cover;
    background-position: left bottom;
    background-repeat: no-repeat;
    width: 100%;
    height: 205vh;

    .project-row {
      .project-col1 {
      }

      .project-col2 {
        .carousel_top {
          background-color: white;
          margin-top: -322px;

          .carousel-item.active,
          .carousel-item-next,
          .carousel-item-prev {
            display: block;
          }

          .carousel-item.active {
            h3 {
              font: 30px "Museo Slab 700";
              margin: 0;
            }

            .project-text {
              margin-bottom: 2rem;
              margin-top: 7rem;
              text-align: left;

              p {
                font: 18px "Museo Slab 300";
                line-height: 27px;
                padding-bottom: 10px;
              }
            }
          }

          .carousel-control-prev-icon {
            background-image: url(../../assets/images/large/arrow_L.png);
            width: 22px;
            height: 22px;
          }

          .carousel-control-next-icon {
            background-image: url(../../assets/images/large/arrow_R.png);
            width: 22px;
            height: 22px;
          }

          .carousel-control-next,
          .carousel-control-prev {
            height: 5%;
            top: 25px;
          }
        }

        .project-table {
          margin-top: 55px;
          padding-left: 6%;
          padding-top: 9%;
          background-color: white;

          .table-main {
            table th:last-of-type,
            table td:last-of-type {
              text-align: right;
            }

            table {
              th {
                color: #fff;
                padding: 0.5rem;
                white-space: nowrap;
                text-align: left;
                border-right: 1px solid #fff;
              }

              td {
                height: 30px;
                padding: 0.5rem;
                white-space: nowrap;
                text-align: left;
                border-right: 1px solid #fff;
              }
            }

            table {
              font: 15px "Museo Slab 300";
              margin-bottom: 6.5rem;
              border-collapse: collapse;
              border-spacing: 0;

              thead tr {
                background: #4472c8 !important;
              }
            }

            table tbody tr:nth-child(odd) {
              background: #ccd3e9;
            }

            table tbody tr:nth-child(even) {
              background: #e8ecf5;
            }
          }

          .blueprint {
            margin-top: -90px;

            .carousel-control-next,
            .carousel-control-prev {
              top: 136px;
            }

            .carousel-control-next,
            .carousel-control-prev {
              width: 10%;
              height: 5%;
            }

            .carousel-control-prev-icon {
              background-image: url(../../assets/images/large/arrow_L.png);
              width: 22px;
              height: 22px;
            }

            .carousel-control-next-icon {
              background-image: url(../../assets/images/large/arrow_R.png);
              width: 22px;
              height: 22px;
            }

            .text-center {
              text-align: left !important;
            }

            img {
              width: 91%;
              height: 70%;
              padding-left: 52px;
            }
          }
        }
      }
    }
  }
}

// media
@media screen and (min-width: 1682px) and (max-width: 1981px) {
  .project-container .houses_main1 {
    width: 100%;
    height: 157vh;
  }

  .houses_main1 .project-row .project-col2 .project-table .blueprint {
    img {
      padding-left: 87px !important;
    }

    .carousel-control-prev {
      top: 226px !important;
    }

    .carousel-control-next {
      top: 232px !important;
    }
  }

  // 2
  .project-container .houses_main2 {
    width: 100%;
    height: 157vh;
  }

  .houses_main2 .project-row .project-col2 .project-table .blueprint {
    img {
      padding-left: 87px !important;
    }

    .carousel-control-prev {
      top: 226px !important;
    }

    .carousel-control-next {
      top: 232px !important;
    }
  }

  // 3
  .project-container .houses_main2 {
    width: 100%;
    height: 157vh;
  }

  .houses_main2 .project-row .project-col2 .project-table .blueprint {
    img {
      padding-left: 87px !important;
    }

    .carousel-control-prev {
      top: 226px !important;
    }

    .carousel-control-next {
      top: 232px !important;
    }
  }
}

@media screen and (min-width: 1440px) and (max-width: 1682px) {
  .project-container .houses_main1 {
    width: 100%;
    height: 157vh;
  }

  .houses_main1 .project-row .project-col2 .project-table .blueprint {
    img {
      padding-left: 84px;
    }

    .carousel-control-prev {
      top: 196px !important;
    }

    .carousel-control-next {
      top: 202px !important;
    }
  }

  // 2
  .project-container .houses_main2 {
    width: 100%;
    height: 157vh;
  }

  .houses_main2 .project-row .project-col2 .project-table .blueprint {
    img {
      padding-left: 84px;
    }

    .carousel-control-prev {
      top: 196px !important;
    }

    .carousel-control-next {
      top: 202px !important;
    }
  }

  // 3
  .project-container .houses_main3 {
    width: 100%;
    height: 157vh;
  }

  .houses_main3 .project-row .project-col2 .project-table .blueprint {
    img {
      padding-left: 84px;
    }

    .carousel-control-prev {
      top: 196px !important;
    }

    .carousel-control-next {
      top: 202px !important;
    }
  }
}

// @media screen and (min-width: 1140px) and (max-width: 1440px) {
//   .project-container
//     .houses_main1
//     .project-row
//     .project-col2
//     .project-table
//     .table-main
//     table {
//     font: 13px "Museo Slab 300";
//   }
//   .project-container .houses_main1 {
//     width: 100%;
//     height: 157vh;
//   }
//   .houses_main1 .project-row .project-col2 .project-table .blueprint {
//     img {
//       padding-left: 84px;
//     }
//     // .carousel-control-prev {
//     //   top: 196px !important;
//     // }
//     // .carousel-control-next {
//     //   top: 202px !important;
//     // }
//   }
//   // 2
//   .project-container
//     .houses_main2
//     .project-row
//     .project-col2
//     .project-table
//     .table-main
//     table {
//     font: 13px "Museo Slab 300";
//   }
//   .project-container .houses_main2 {
//     width: 100%;
//     height: 157vh;
//   }
//   .houses_main2 .project-row .project-col2 .project-table .blueprint {
//     img {
//       padding-left: 84px;
//     }
//     // .carousel-control-prev {
//     //   top: 196px !important;
//     // }
//     // .carousel-control-next {
//     //   top: 202px !important;
//     // }
//   }
//   // 3
//   .project-container
//     .houses_main3
//     .project-row
//     .project-col2
//     .project-table
//     .table-main
//     table {
//     font: 13px "Museo Slab 300";
//   }
//   .project-container .houses_main3 {
//     width: 100%;
//     height: 157vh;
//   }
//   .houses_main3 .project-row .project-col2 .project-table .blueprint {
//     img {
//       padding-left: 84px;
//     }
//     // .carousel-control-prev {
//     //   top: 196px !important;
//     // }
//     // .carousel-control-next {
//     //   top: 202px !important;
//     // }
//   }
// }

@media screen and (min-width: 1030px) and (max-width: 1140px) {
  .project-container .houses_main1 .project-row .project-col2 {
    .project-table {
      .table-main table {
        font: 12px "Museo Slab 300";
      }

      .blueprint {
        margin-top: -93px;
        margin-left: -27px;

        img {
          width: 420px;
          height: 250px;
        }
      }
    }

    .carousel_top .carousel-item.active {
      .project-text {
        margin-top: 3rem;

        p {
          font: 16px "Museo Slab 300";
        }

        h3 {
          font: 25px "Museo Slab 700";
        }
      }
    }
  }

  // 2
  .project-container .houses_main2 .project-row .project-col2 {
    .project-table {
      .table-main table {
        font: 12px "Museo Slab 300";
      }

      .blueprint {
        margin-top: -93px;
        margin-left: -27px;

        img {
          width: 420px;
          height: 250px;
        }
      }
    }

    .carousel_top .carousel-item.active {
      .project-text {
        margin-top: 3rem;

        p {
          font: 16px "Museo Slab 300";
        }

        h3 {
          font: 25px "Museo Slab 700";
        }
      }
    }
  }

  // 3
  .project-container .houses_main3 .project-row .project-col2 {
    .project-table {
      .table-main table {
        font: 12px "Museo Slab 300";
      }

      .blueprint {
        margin-top: -93px;
        margin-left: -27px;

        img {
          width: 420px;
          height: 250px;
        }
      }
    }

    .carousel_top .carousel-item.active {
      .project-text {
        margin-top: 3rem;

        p {
          font: 16px "Museo Slab 300";
        }

        h3 {
          font: 25px "Museo Slab 700";
        }
      }
    }
  }

  .project-container .button-hold button {
    font: 15px "Museo Slab 300";
  }
}

@media screen and (min-width: 952px) and (max-width: 1030px) {
  .project-container .houses_main1 {
    width: 94vw;
    height: 151vh;

    .project-row .project-col2 {
      .project-table {
        .table-main table {
          font: 10px "Museo Slab 300";
        }

        .blueprint {
          margin-top: -113px;
          margin-left: -10px;

          img {
            width: 380px;
            height: 227px;
          }

          .carousel-control-prev {
            top: 108px;
          }

          .carousel-control-next {
            top: 110px;
          }
        }
      }

      .carousel_top .carousel-item.active {
        .project-text {
          margin-top: 3rem;

          p {
            font: 13px "Museo Slab 300";
          }

          h3 {
            font: 20px "Museo Slab 700";
          }
        }
      }
    }
  }

  //2
  .project-container .houses_main2 {
    width: 94vw;
    height: 151vh;

    .project-row .project-col2 {
      .project-table {
        .table-main table {
          font: 10px "Museo Slab 300";
        }

        .blueprint {
          margin-top: -113px;
          margin-left: -10px;

          img {
            width: 380px;
            height: 227px;
          }

          .carousel-control-prev {
            top: 108px;
          }

          .carousel-control-next {
            top: 110px;
          }
        }
      }

      .carousel_top .carousel-item.active {
        .project-text {
          margin-top: 3rem;

          p {
            font: 13px "Museo Slab 300";
          }

          h3 {
            font: 20px "Museo Slab 700";
          }
        }
      }
    }
  }

  //3
  .project-container .houses_main3 {
    width: 94vw;
    height: 151vh;

    .project-row .project-col2 {
      .project-table {
        .table-main table {
          font: 10px "Museo Slab 300";
        }

        .blueprint {
          margin-top: -113px;
          margin-left: -10px;

          img {
            width: 380px;
            height: 227px;
          }

          .carousel-control-prev {
            top: 108px;
          }

          .carousel-control-next {
            top: 110px;
          }
        }
      }

      .carousel_top .carousel-item.active {
        .project-text {
          margin-top: 3rem;

          p {
            font: 13px "Museo Slab 300";
          }

          h3 {
            font: 20px "Museo Slab 700";
          }
        }
      }
    }
  }

  .project-container .button-hold button {
    font: 12px "Museo Slab 300";
  }
}

@media screen and (min-width: 910px) and (max-width: 952px) {
  .project-container .houses_main1 {
    width: 94vw;
    height: 151vh;

    .project-row .project-col2 {
      .project-table {
        .table-main table {
          font: 10px "Museo Slab 300";
        }

        .blueprint {
          margin-top: -113px;
          margin-left: -10px;

          img {
            width: 340px;
            height: 210px;
          }

          .carousel-control-prev {
            top: 108px;
          }

          .carousel-control-next {
            top: 110px;
          }
        }
      }

      .carousel_top .carousel-item.active {
        .project-text {
          margin-top: 3rem;

          p {
            font: 13px "Museo Slab 300";
          }

          h3 {
            font: 20px "Museo Slab 700";
          }
        }
      }
    }
  }

  //2
  .project-container .houses_main2 {
    width: 94vw;
    height: 151vh;

    .project-row .project-col2 {
      .project-table {
        .table-main table {
          font: 10px "Museo Slab 300";
        }

        .blueprint {
          margin-top: -113px;
          margin-left: -10px;

          img {
            width: 340px;
            height: 210px;
          }

          .carousel-control-prev {
            top: 108px;
          }

          .carousel-control-next {
            top: 110px;
          }
        }
      }

      .carousel_top .carousel-item.active {
        .project-text {
          margin-top: 3rem;

          p {
            font: 13px "Museo Slab 300";
          }

          h3 {
            font: 20px "Museo Slab 700";
          }
        }
      }
    }
  }

  //3
  .project-container .houses_main3 {
    width: 94vw;
    height: 151vh;

    .project-row .project-col2 {
      .project-table {
        .table-main table {
          font: 10px "Museo Slab 300";
        }

        .blueprint {
          margin-top: -113px;
          margin-left: -10px;

          img {
            width: 340px;
            height: 210px;
          }

          .carousel-control-prev {
            top: 108px;
          }

          .carousel-control-next {
            top: 110px;
          }
        }
      }

      .carousel_top .carousel-item.active {
        .project-text {
          margin-top: 3rem;

          p {
            font: 13px "Museo Slab 300";
          }

          h3 {
            font: 20px "Museo Slab 700";
          }
        }
      }
    }
  }

  .project-container .button-hold button {
    font: 12px "Museo Slab 300";
  }
}

@media screen and (min-width: 834px) and (max-width: 910px) {
  .project-container .houses_main1 {
    width: 94vw;
    height: 151vh;

    .project-row .project-col2 {
      .project-table {
        .table-main table {
          font: 8px "Museo Slab 300";
        }

        .blueprint {
          margin-top: -113px;
          margin-left: -10px;

          img {
            width: 317px;
            height: 210px;
            padding-left: 33px;
          }

          .carousel-control-prev {
            top: 108px;
          }

          .carousel-control-next {
            top: 110px;
          }
        }
      }

      .carousel_top .carousel-item.active {
        .project-text {
          margin-top: 3rem;

          p {
            font: 13px "Museo Slab 300";
          }

          h3 {
            font: 20px "Museo Slab 700";
          }
        }
      }
    }
  }

  //2
  .project-container .houses_main2 {
    width: 94vw;
    height: 151vh;

    .project-row .project-col2 {
      .project-table {
        .table-main table {
          font: 8px "Museo Slab 300";
        }

        .blueprint {
          margin-top: -113px;
          margin-left: -10px;

          img {
            width: 317px;
            height: 210px;
            padding-left: 33px;
          }

          .carousel-control-prev {
            top: 108px;
          }

          .carousel-control-next {
            top: 110px;
          }
        }
      }

      .carousel_top .carousel-item.active {
        .project-text {
          margin-top: 3rem;

          p {
            font: 13px "Museo Slab 300";
          }

          h3 {
            font: 20px "Museo Slab 700";
          }
        }
      }
    }
  }

  //3
  .project-container .houses_main3 {
    width: 94vw;
    height: 151vh;

    .project-row .project-col2 {
      .project-table {
        .table-main table {
          font: 8px "Museo Slab 300";
        }

        .blueprint {
          margin-top: -113px;
          margin-left: -10px;

          img {
            width: 317px;
            height: 210px;
            padding-left: 33px;
          }

          .carousel-control-prev {
            top: 108px;
          }

          .carousel-control-next {
            top: 110px;
          }
        }
      }

      .carousel_top .carousel-item.active {
        .project-text {
          margin-top: 3rem;

          p {
            font: 13px "Museo Slab 300";
          }

          h3 {
            font: 20px "Museo Slab 700";
          }
        }
      }
    }
  }

  .project-container .button-hold button {
    font: 12px "Museo Slab 300";
  }
}

@media screen and (min-width: 767px) and (max-width: 834px) {
  .project-container .houses_main1 {
    width: 94vw;
    height: 74vh;

    .project-row .project-col2 {
      .project-table {
        .table-main table {
          td {
            height: 15px;
          }

          font: 7px "Museo Slab 300";
        }

        .blueprint {
          margin-top: -113px;
          margin-left: -0px;

          img {
            // width: 284px;
            // height: 200px;
            padding-left: 21px;
          }
        }
      }

      .carousel_top .carousel-item.active {
        h3 {
          font: 17px "Museo Slab 700";
        }

        .project-text {
          margin-top: 1rem;
          margin-bottom: 1rem;

          p {
            font: 13px "Museo Slab 300";
          }

          h3 {
            font: 15px "Museo Slab 700";
          }
        }
      }
    }
  }

  .project-container .button-hold button {
    font: 12px "Museo Slab 300";
  }

  .project-container
    .houses_main1
    .project-row
    .project-col2
    .project-table
    .blueprint
    .carousel-control-next,
  .project-container
    .houses_main1
    .project-row
    .project-col2
    .project-table
    .blueprint
    .carousel-control-prev {
    top: 103px;
  }

  .project-container .houses_main1 .project-row .project-col2 .carousel_top {
    .carousel-control-prev-icon {
      width: 19px;
      height: 19px;
    }

    .carousel-control-next-icon {
      width: 19px;
      height: 19px;
    }
  }

  //2
  .project-container .houses_main2 {
    width: 94vw;
    height: 74vh;

    .project-row .project-col2 {
      .project-table {
        .table-main table {
          td {
            height: 15px;
          }

          font: 7px "Museo Slab 300";
        }

        .blueprint {
          margin-top: -113px;
          margin-left: -0px;

          img {
            // width: 284px;
            // height: 200px;
            padding-left: 21px;
          }
        }
      }

      .carousel_top .carousel-item.active {
        h3 {
          font: 17px "Museo Slab 700";
        }

        .project-text {
          margin-top: 1rem;
          margin-bottom: 1rem;

          p {
            font: 13px "Museo Slab 300";
          }

          h3 {
            font: 15px "Museo Slab 700";
          }
        }
      }
    }
  }

  .project-container .button-hold button {
    font: 12px "Museo Slab 300";
  }

  .project-container
    .houses_main2
    .project-row
    .project-col2
    .project-table
    .blueprint
    .carousel-control-next,
  .project-container
    .houses_main2
    .project-row
    .project-col2
    .project-table
    .blueprint
    .carousel-control-prev {
    top: 103px;
  }

  .project-container .houses_main2 .project-row .project-col2 .carousel_top {
    .carousel-control-prev-icon {
      width: 19px;
      height: 19px;
    }

    .carousel-control-next-icon {
      width: 19px;
      height: 19px;
    }
  }

  //3
  .project-container .houses_main3 {
    width: 94vw;
    height: 74vh;

    .project-row .project-col2 {
      .project-table {
        .table-main table {
          td {
            height: 15px;
          }

          font: 7px "Museo Slab 300";
        }

        .blueprint {
          margin-top: -113px;
          margin-left: -0px;

          img {
            // width: 284px;
            // height: 200px;
            padding-left: 21px;
          }
        }
      }

      .carousel_top .carousel-item.active {
        h3 {
          font: 17px "Museo Slab 700";
        }

        .project-text {
          margin-top: 1rem;
          margin-bottom: 1rem;

          p {
            font: 13px "Museo Slab 300";
          }

          h3 {
            font: 15px "Museo Slab 700";
          }
        }
      }
    }
  }

  .project-container .button-hold button {
    font: 12px "Museo Slab 300";
  }

  .project-container
    .houses_main3
    .project-row
    .project-col2
    .project-table
    .blueprint
    .carousel-control-next,
  .project-container
    .houses_main3
    .project-row
    .project-col2
    .project-table
    .blueprint
    .carousel-control-prev {
    top: 103px;
  }

  .project-container .houses_main3 .project-row .project-col2 .carousel_top {
    .carousel-control-prev-icon {
      width: 19px;
      height: 19px;
    }

    .carousel-control-next-icon {
      width: 19px;
      height: 19px;
    }
  }
}

@media screen and (min-width: 606px) and (max-width: 766px) {
  .project-container .button-hold button {
    margin-left: 120%;
  }

  .project-container .houses_main1 .project-row .project-col2 .carousel_top {
    margin-top: -222px;
  }

  .project-container .houses_main1 {
    width: 94vw;
    height: 128vh;

    .project-row .project-col2 {
      .project-table {
        .table-main table {
          td {
            height: 15px;
          }

          font: 7px "Museo Slab 300";
        }

        .blueprint {
          margin-top: -113px;
          margin-left: -0px;

          img {
            // width: 370px;
            // height: 228px;
            padding-left: 40px;
          }
        }
      }

      .carousel_top .carousel-item.active {
        h3 {
          font: 17px "Museo Slab 700";
        }

        .project-text {
          margin-top: 1rem;
          margin-bottom: 1rem;

          p {
            font: 13px "Museo Slab 300";
          }

          h3 {
            font: 15px "Museo Slab 700";
          }
        }
      }
    }
  }

  .project-container .button-hold button {
    font: 12px "Museo Slab 300";
  }

  .project-container
    .houses_main1
    .project-row
    .project-col2
    .project-table
    .blueprint
    .carousel-control-next,
  .project-container
    .houses_main1
    .project-row
    .project-col2
    .project-table
    .blueprint
    .carousel-control-prev {
    top: 103px;
  }

  .project-container .houses_main1 .project-row .project-col2 .carousel_top {
    .carousel-control-prev-icon {
      width: 19px;
      height: 19px;
    }

    .carousel-control-next-icon {
      width: 19px;
      height: 19px;
    }
  }

  //2
  .project-container .button-hold button {
    margin-left: 120%;
  }

  .project-container .houses_main2 .project-row .project-col2 .carousel_top {
    margin-top: -222px;
  }

  .project-container .houses_main2 {
    width: 94vw;
    height: 128vh;

    .project-row .project-col2 {
      .project-table {
        .table-main table {
          td {
            height: 15px;
          }

          font: 7px "Museo Slab 300";
        }

        .blueprint {
          margin-top: -113px;
          margin-left: -0px;

          img {
            // width: 370px;
            // height: 228px;
            padding-left: 40px;
          }
        }
      }

      .carousel_top .carousel-item.active {
        h3 {
          font: 17px "Museo Slab 700";
        }

        .project-text {
          margin-top: 1rem;
          margin-bottom: 1rem;

          p {
            font: 13px "Museo Slab 300";
          }

          h3 {
            font: 15px "Museo Slab 700";
          }
        }
      }
    }
  }

  .project-container .button-hold button {
    font: 12px "Museo Slab 300";
  }

  .project-container
    .houses_main2
    .project-row
    .project-col2
    .project-table
    .blueprint
    .carousel-control-next,
  .project-container
    .houses_main2
    .project-row
    .project-col2
    .project-table
    .blueprint
    .carousel-control-prev {
    top: 103px;
  }

  .project-container .houses_main2 .project-row .project-col2 .carousel_top {
    .carousel-control-prev-icon {
      width: 19px;
      height: 19px;
    }

    .carousel-control-next-icon {
      width: 19px;
      height: 19px;
    }
  }

  //3
  .project-container .button-hold button {
    margin-left: 120%;
  }

  .project-container .houses_main3 .project-row .project-col2 .carousel_top {
    margin-top: -222px;
  }

  .project-container .houses_main3 {
    width: 94vw;
    height: 128vh;

    .project-row .project-col2 {
      .project-table {
        .table-main table {
          td {
            height: 15px;
          }

          font: 7px "Museo Slab 300";
        }

        .blueprint {
          margin-top: -113px;
          margin-left: -0px;

          img {
            // width: 370px;
            // height: 228px;
            padding-left: 40px;
          }
        }
      }

      .carousel_top .carousel-item.active {
        h3 {
          font: 17px "Museo Slab 700";
        }

        .project-text {
          margin-top: 1rem;
          margin-bottom: 1rem;

          p {
            font: 13px "Museo Slab 300";
          }

          h3 {
            font: 15px "Museo Slab 700";
          }
        }
      }
    }
  }

  .project-container .button-hold button {
    font: 12px "Museo Slab 300";
  }

  .project-container
    .houses_main3
    .project-row
    .project-col2
    .project-table
    .blueprint
    .carousel-control-next,
  .project-container
    .houses_main3
    .project-row
    .project-col2
    .project-table
    .blueprint
    .carousel-control-prev {
    top: 103px;
  }

  .project-container .houses_main3 .project-row .project-col2 .carousel_top {
    .carousel-control-prev-icon {
      width: 19px;
      height: 19px;
    }

    .carousel-control-next-icon {
      width: 19px;
      height: 19px;
    }
  }
}

@media screen and (min-width: 440px) and (max-width: 606px) {
  .project-container .button-hold button {
    margin-left: 161px;
  }

  .project-container .houses_main1 .project-row .project-col2 .carousel_top {
    margin-top: -222px;
  }

  .project-container .houses_main1 {
    width: 93vw;
    height: 83vh;

    .project-row .project-col2 {
      .project-table {
        .table-main table {
          td {
            height: 15px;
          }

          font: 7px "Museo Slab 300";
        }

        .blueprint {
          margin-top: -113px;
          margin-left: -4px;

          img {
            // width: 300px;
            // height: 228px;
            padding-left: 21px;
          }
        }
      }

      .carousel_top .carousel-item.active {
        h3 {
          font: 17px "Museo Slab 700";
        }

        .project-text {
          margin-top: 1rem;
          margin-bottom: 1rem;

          p {
            font: 11px "Museo Slab 300";
          }

          h3 {
            font: 15px "Museo Slab 700";
          }
        }
      }
    }
  }

  .project-container .button-hold button {
    font: 12px "Museo Slab 300";
  }

  .project-container
    .houses_main1
    .project-row
    .project-col2
    .project-table
    .blueprint
    .carousel-control-next,
  .project-container
    .houses_main1
    .project-row
    .project-col2
    .project-table
    .blueprint
    .carousel-control-prev {
    top: 103px;
  }

  .project-container .houses_main1 .project-row .project-col2 .carousel_top {
    .carousel-control-prev-icon {
      width: 19px;
      height: 19px;
    }

    .carousel-control-next-icon {
      width: 19px;
      height: 19px;
    }
  }

  //2
  .project-container .button-hold button {
    margin-left: 161px;
  }

  .project-container .houses_main2 .project-row .project-col2 .carousel_top {
    margin-top: -222px;
  }

  .project-container .houses_main2 {
    width: 93vw;
    height: 83vh;

    .project-row .project-col2 {
      .project-table {
        .table-main table {
          td {
            height: 15px;
          }

          font: 7px "Museo Slab 300";
        }

        .blueprint {
          margin-top: -113px;
          margin-left: -4px;

          img {
            // width: 300px;
            // height: 228px;
            padding-left: 21px;
          }
        }
      }

      .carousel_top .carousel-item.active {
        h3 {
          font: 17px "Museo Slab 700";
        }

        .project-text {
          margin-top: 1rem;
          margin-bottom: 1rem;

          p {
            font: 11px "Museo Slab 300";
          }

          h3 {
            font: 15px "Museo Slab 700";
          }
        }
      }
    }
  }

  .project-container .button-hold button {
    font: 12px "Museo Slab 300";
  }

  .project-container
    .houses_main2
    .project-row
    .project-col2
    .project-table
    .blueprint
    .carousel-control-next,
  .project-container
    .houses_main2
    .project-row
    .project-col2
    .project-table
    .blueprint
    .carousel-control-prev {
    top: 103px;
  }

  .project-container .houses_main2 .project-row .project-col2 .carousel_top {
    .carousel-control-prev-icon {
      width: 19px;
      height: 19px;
    }

    .carousel-control-next-icon {
      width: 19px;
      height: 19px;
    }
  }

  //3
  .project-container .button-hold button {
    margin-left: 161px;
  }

  .project-container .houses_main3 .project-row .project-col2 .carousel_top {
    margin-top: -222px;
  }

  .project-container .houses_main3 {
    width: 93vw;
    height: 83vh;

    .project-row .project-col2 {
      .project-table {
        .table-main table {
          td {
            height: 15px;
          }

          font: 7px "Museo Slab 300";
        }

        .blueprint {
          margin-top: -113px;
          margin-left: -4px;

          img {
            // width: 300px;
            // height: 228px;
            padding-left: 21px;
          }
        }
      }

      .carousel_top .carousel-item.active {
        h3 {
          font: 17px "Museo Slab 700";
        }

        .project-text {
          margin-top: 1rem;
          margin-bottom: 1rem;

          p {
            font: 11px "Museo Slab 300";
          }

          h3 {
            font: 15px "Museo Slab 700";
          }
        }
      }
    }
  }

  .project-container .button-hold button {
    font: 12px "Museo Slab 300";
  }

  .project-container
    .houses_main3
    .project-row
    .project-col2
    .project-table
    .blueprint
    .carousel-control-next,
  .project-container
    .houses_main3
    .project-row
    .project-col2
    .project-table
    .blueprint
    .carousel-control-prev {
    top: 103px;
  }

  .project-container .houses_main3 .project-row .project-col2 .carousel_top {
    .carousel-control-prev-icon {
      width: 19px;
      height: 19px;
    }

    .carousel-control-next-icon {
      width: 19px;
      height: 19px;
    }
  }
}

@media screen and (min-width: 364px) and (max-width: 440px) {
  .project-container .button-hold button {
    margin-left: 111px;
  }

  .project-container .houses_main1 .project-row .project-col2 .carousel_top {
    margin-top: -222px;
  }

  .project-container .houses_main1 {
    width: 92vw;
    height: 83vh;

    .project-row .project-col2 {
      .project-table {
        .table-main table {
          td {
            height: 15px;
          }

          font: 5px "Museo Slab 300";
        }

        .blueprint {
          margin-top: -113px;
          margin-left: 0px;

          img {
            // width: 300px;
            // height: 228px;
            padding-left: 21px;
          }
        }
      }

      .carousel_top .carousel-item.active {
        h3 {
          font: 13px "Museo Slab 700";
        }

        .project-text {
          margin-top: 1rem;
          margin-bottom: 1rem;

          p {
            font: 12px "Museo Slab 300";
          }

          h3 {
            font: 15px "Museo Slab 700";
          }
        }
      }
    }
  }

  .project-container .button-hold button {
    font: 12px "Museo Slab 300";
  }

  .project-container
    .houses_main1
    .project-row
    .project-col2
    .project-table
    .blueprint
    .carousel-control-next,
  .project-container
    .houses_main1
    .project-row
    .project-col2
    .project-table
    .blueprint
    .carousel-control-prev {
    top: 80px;
  }

  .project-container .houses_main1 .project-row .project-col2 .carousel_top {
    .carousel-control-prev-icon {
      width: 19px;
      height: 19px;
    }

    .carousel-control-next-icon {
      width: 19px;
      height: 19px;
    }
  }

  //2
  .project-container .button-hold button {
    margin-left: 111px;
  }

  .project-container .houses_main2 .project-row .project-col2 .carousel_top {
    margin-top: -222px;
  }

  .project-container .houses_main2 {
    width: 92vw;
    height: 83vh;

    .project-row .project-col2 {
      .project-table {
        .table-main table {
          td {
            height: 15px;
          }

          font: 5px "Museo Slab 300";
        }

        .blueprint {
          margin-top: -113px;
          margin-left: 0px;

          img {
            // width: 300px;
            // height: 228px;
            padding-left: 21px;
          }
        }
      }

      .carousel_top .carousel-item.active {
        h3 {
          font: 13px "Museo Slab 700";
        }

        .project-text {
          margin-top: 1rem;
          margin-bottom: 1rem;

          p {
            font: 12px "Museo Slab 300";
          }

          h3 {
            font: 15px "Museo Slab 700";
          }
        }
      }
    }
  }

  .project-container .button-hold button {
    font: 12px "Museo Slab 300";
  }

  .project-container
    .houses_main2
    .project-row
    .project-col2
    .project-table
    .blueprint
    .carousel-control-next,
  .project-container
    .houses_main2
    .project-row
    .project-col2
    .project-table
    .blueprint
    .carousel-control-prev {
    top: 80px;
  }

  .project-container .houses_main2 .project-row .project-col2 .carousel_top {
    .carousel-control-prev-icon {
      width: 19px;
      height: 19px;
    }

    .carousel-control-next-icon {
      width: 19px;
      height: 19px;
    }
  }

  //3
  .project-container .button-hold button {
    margin-left: 111px;
  }

  .project-container .houses_main3 .project-row .project-col2 .carousel_top {
    margin-top: -222px;
  }

  .project-container .houses_main3 {
    width: 92vw;
    height: 83vh;

    .project-row .project-col2 {
      .project-table {
        .table-main table {
          td {
            height: 15px;
          }

          font: 5px "Museo Slab 300";
        }

        .blueprint {
          margin-top: -113px;
          margin-left: 0px;

          img {
            // width: 300px;
            // height: 228px;
            padding-left: 21px;
          }
        }
      }

      .carousel_top .carousel-item.active {
        h3 {
          font: 13px "Museo Slab 700";
        }

        .project-text {
          margin-top: 1rem;
          margin-bottom: 1rem;

          p {
            font: 12px "Museo Slab 300";
          }

          h3 {
            font: 15px "Museo Slab 700";
          }
        }
      }
    }
  }

  .project-container .button-hold button {
    font: 12px "Museo Slab 300";
  }

  .project-container
    .houses_main3
    .project-row
    .project-col2
    .project-table
    .blueprint
    .carousel-control-next,
  .project-container
    .houses_main3
    .project-row
    .project-col2
    .project-table
    .blueprint
    .carousel-control-prev {
    top: 80px;
  }

  .project-container .houses_main3 .project-row .project-col2 .carousel_top {
    .carousel-control-prev-icon {
      width: 19px;
      height: 19px;
    }

    .carousel-control-next-icon {
      width: 19px;
      height: 19px;
    }
  }
}

@media screen and (min-width: 100px) and (max-width: 364px) {
  .project-container
    .houses_main1
    .project-row
    .project-col2
    .project-table
    .blueprint {
    .carousel-control-next-icon {
      width: 18px;
      height: 18px;
    }

    .carousel-control-prev-icon {
      width: 18px;
      height: 18px;
    }
  }

  .project-container .button-hold button {
    margin-left: 69px;
  }

  .project-container .houses_main1 .project-row .project-col2 .carousel_top {
    margin-top: -222px;
  }

  .project-container .houses_main1 {
    width: 90vw;
    height: 81vh;

    .project-row .project-col2 {
      .project-table {
        .table-main table {
          td {
            height: 15px;
          }

          font: 4px "Museo Slab 300";
        }

        .blueprint {
          margin-top: -113px;
          margin-left: 0px;

          img {
            // width: 300px;
            // height: 228px;
            padding-left: 21px;
          }
        }
      }

      .carousel_top .carousel-item.active {
        h3 {
          font: 13px "Museo Slab 700";
        }

        .project-text {
          margin-top: 1rem;
          margin-bottom: 1rem;

          p {
            font: 8px "Museo Slab 300";
          }

          h3 {
            font: 15px "Museo Slab 700";
          }
        }
      }
    }
  }

  .project-container .button-hold button {
    font: 12px "Museo Slab 300";
  }

  .project-container
    .houses_main1
    .project-row
    .project-col2
    .project-table
    .blueprint
    .carousel-control-next,
  .project-container
    .houses_main1
    .project-row
    .project-col2
    .project-table
    .blueprint
    .carousel-control-prev {
    top: 68px;
  }

  .project-container .houses_main1 .project-row .project-col2 .carousel_top {
    .carousel-control-prev-icon {
      width: 19px;
      height: 19px;
    }

    .carousel-control-next-icon {
      width: 19px;
      height: 19px;
    }
  }

  //2
  .project-container
    .houses_main2
    .project-row
    .project-col2
    .project-table
    .blueprint {
    .carousel-control-next-icon {
      width: 18px;
      height: 18px;
    }

    .carousel-control-prev-icon {
      width: 18px;
      height: 18px;
    }
  }

  .project-container .button-hold button {
    margin-left: 69px;
  }

  .project-container .houses_main2 .project-row .project-col2 .carousel_top {
    margin-top: -222px;
  }

  .project-container .houses_main2 {
    width: 90vw;
    height: 81vh;

    .project-row .project-col2 {
      .project-table {
        .table-main table {
          td {
            height: 15px;
          }

          font: 4px "Museo Slab 300";
        }

        .blueprint {
          margin-top: -113px;
          margin-left: 0px;

          img {
            // width: 300px;
            // height: 228px;
            padding-left: 21px;
          }
        }
      }

      .carousel_top .carousel-item.active {
        h3 {
          font: 13px "Museo Slab 700";
        }

        .project-text {
          margin-top: 1rem;
          margin-bottom: 1rem;

          p {
            font: 8px "Museo Slab 300";
          }

          h3 {
            font: 15px "Museo Slab 700";
          }
        }
      }
    }
  }

  .project-container .button-hold button {
    font: 12px "Museo Slab 300";
  }

  .project-container
    .houses_main2
    .project-row
    .project-col2
    .project-table
    .blueprint
    .carousel-control-next,
  .project-container
    .houses_main2
    .project-row
    .project-col2
    .project-table
    .blueprint
    .carousel-control-prev {
    top: 68px;
  }

  .project-container .houses_main2 .project-row .project-col2 .carousel_top {
    .carousel-control-prev-icon {
      width: 19px;
      height: 19px;
    }

    .carousel-control-next-icon {
      width: 19px;
      height: 19px;
    }
  }

  //3
  .project-container
    .houses_main3
    .project-row
    .project-col2
    .project-table
    .blueprint {
    .carousel-control-next-icon {
      width: 18px;
      height: 18px;
    }

    .carousel-control-prev-icon {
      width: 18px;
      height: 18px;
    }
  }

  .project-container .button-hold button {
    margin-left: 69px;
  }

  .project-container .houses_main3 .project-row .project-col2 .carousel_top {
    margin-top: -222px;
  }

  .project-container .houses_main3 {
    width: 90vw;
    height: 81vh;

    .project-row .project-col2 {
      .project-table {
        .table-main table {
          td {
            height: 15px;
          }

          font: 4px "Museo Slab 300";
        }

        .blueprint {
          margin-top: -113px;
          margin-left: 0px;

          img {
            // width: 300px;
            // height: 228px;
            padding-left: 21px;
          }
        }
      }

      .carousel_top .carousel-item.active {
        h3 {
          font: 13px "Museo Slab 700";
        }

        .project-text {
          margin-top: 1rem;
          margin-bottom: 1rem;

          p {
            font: 8px "Museo Slab 300";
          }

          h3 {
            font: 15px "Museo Slab 700";
          }
        }
      }
    }
  }

  .project-container .button-hold button {
    font: 12px "Museo Slab 300";
  }

  .project-container
    .houses_main3
    .project-row
    .project-col2
    .project-table
    .blueprint
    .carousel-control-next,
  .project-container
    .houses_main3
    .project-row
    .project-col2
    .project-table
    .blueprint
    .carousel-control-prev {
    top: 68px;
  }

  .project-container .houses_main3 .project-row .project-col2 .carousel_top {
    .carousel-control-prev-icon {
      width: 19px;
      height: 19px;
    }

    .carousel-control-next-icon {
      width: 19px;
      height: 19px;
    }
  }
}



.table>:not(caption)>*>* {
       background-color: transparent !important;
}