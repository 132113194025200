.Whatwedo_wrapper {
  padding: 0%;
  position: relative;

  .flash_image {
    background-image: url(../assets/images/large/boomerang-what-we-do.jpg);
    // height: 586px;
    height: 685px;
    position: relative;
    width: 100%;
    background-size: 100% 100%;
    top: 55px;
    background-repeat: no-repeat;
    margin: 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
    z-index: 5;

    h1 {
      font-family: "Museo Slab 700";
    }

    p {
      font-size: 16px;
    }

    @media screen and (min-width: 950px) and (max-width: 1100px) {
      height: 500px;
      // position: relative;
      // width: 100%;
      background-size: 100% 100%;
      top: 50px;
    }

    @media screen and (min-width: 701px) and (max-width: 950px) {
      height: 500px;
      // position: relative;
      // width: 100%;
      background-size: 100% 100%;
      top: 50px;
    }

    @media screen and (min-width: 500px) and (max-width: 700px) {
      // height: ;
      top: 50px;
      height: 300px;
      background-size: 100% 100%;
      background-image: url(../assets/images/medium/boomerang-what-we-do.jpg);
    }

    @media screen and (max-width: 500px) {
      // height: ;
      top: 50px;
      height: 300px;
      // background-size: 100% 45%;
      // background-size: contain;
      background-image: url(../assets/images/small/boomerang-what-we-do.jpg);
    }

    .flash_inner_content {
      // width: 55%;
      float: left;
      text-align: left;
      padding: 2%;
      margin-top: 50px;
      margin-left: 20px;
      position: relative;
      // background-color: rgba(0, 0, 0, 0.5);
      background-color: #fff;
      color: black !important;
      width: 50%;
      // animation: go 0.7s;

      @keyframes go {
        from {
          opacity: 0;
          left: 0%;
        }

        to {
          opacity: 1;
          left: 100%;
        }
      }

      h1 {
        // margin-top: -50px;
        width: 90%;
        color: #011e60;
        font-weight: 700;
        // margin-top: 0px;
      }

      p {
        width: 90%;
        // font-size: 16px;
      }

      @media screen and (min-width: 740px) and (max-width: 1100px) {
        width: 91%;
      }

      @media screen and (min-width: 590px) and (max-width: 740px) {
        width: 93%;
        padding-top: 30px;

        p {
          font-size: 15px;
        }

        h1 {
          font-size: 30px;
        }
      }

      @media screen and (min-width: 500px) and (max-width: 590px) {
        width: 93%;
        padding-top: 30px;

        // padding-top: 10px;
        p {
          font-size: 13px;
        }

        h1 {
          // margin-top: -26px;
          font-size: 23px;
        }
      }

      @media screen and (min-width: 200px) and (max-width: 500px) {
        padding-top: 30px;
        width: 93%;

        // padding-top: 10px;
        p {
          width: 90%;
          font-size: 11px;
          margin-top: -11px;
        }

        h1 {
          // position: absolute;
          // margin-top: -30px;
          font-size: 16px;
        }
      }

      .contact_button {
        padding: 20px;
        width: 250px;
        font-size: 17px;
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid black;
        border-radius: 0px;
        color: black;

        @media screen and (min-width: 200px) and (max-width: 500px) {
          width: 100%;
          width: 170px;
          font-size: 14px;
          padding: 10px;
        }
      }
    }
  }

  .what-we-do-new-img {
    // border: 1px solid;
    display: flex;
    justify-content: center;
    // margin: 0 auto;
    margin: 100px;

    @media screen and (min-width: 200px) and (max-width: 500px) {
      width: 90%;
      margin: 0 auto;
      margin-top: 90px;
    }

    .what-we-img {
      // width: 100%;
      height: 400px;

      transition: transform 0.3s ease; /* Added transition for smooth effect */

      &:hover {
        transform: scale(1.05); /* Zoom in effect on hover */
      }

      @media screen and (min-width: 200px) and (max-width: 500px) {
        width: 100%;
        height: 400px;
      }

      @keyframes scalCenter {
        0% {
          transform: scale(0.5);
        }

        100% {
          transform: scale(1);
        }
      }

      .activate {
        // animation: fadeIn 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
        animation: scalCenter 2s;

        &:hover {
          // width: 70px !important;
          animation: scalCenter 0.7s ease;
        }
      }
    }
  }

  .second_inner_component {
    // background-image: url(../assets/images/london-uk.jpg);
    position: relative;
    width: 100%;
    // height: 1000px;
    background-repeat: no-repeat;
    background-size: cover;
    // margin-top: 450px;

    margin-top: 360px;
    // display: flex;
    // padding-left: 10%;
    padding-left: 4%;
    padding-right: 4%;
    background: #ffda00;

    @media screen and (min-width: 1100px) and (max-width: 1200px) {
      // height: 1500px;
    }

    @media screen and (min-width: 700px) and (max-width: 1100px) {
      // margin-top: 270px;
      // height: 1500px;
    }

    @media screen and (min-width: 330px) and (max-width: 520px) {
      // margin-top: 226px;
      // height: 1800px;
      font-size: 15px;
    }

    @media screen and (min-width: 520px) and (max-width: 700px) {
      // margin-top: 55px;
      // height: 1700px;

      p {
        font-size: 15px;
      }
    }

    @media screen and (min-width: 200px) and (max-width: 330px) {
      margin-top: 246px;
      // height: 2000px;
      font-size: 15px;

      p {
        font-size: 15px;
      }
    }

    .Inner_card_one {
      padding-top: 50px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      // margin-top: -349px;

      .what-we-do-new-img-icon {
        // border: 1px solid;
        display: flex;
        justify-content: center;

        transition: transform 0.3s ease; /* Added transition for smooth effect */

        &:hover {
          transform: scale(1.05); /* Zoom in effect on hover */
        }

        @media screen and (min-width: 200px) and (max-width: 500px) {
          width: 90%;
          margin: 0 auto;
        }

        .what-we-img {
          // width: 100%;
          height: 400px;

          @media screen and (min-width: 200px) and (max-width: 500px) {
            width: 100%;
            height: 400px;
          }
        }

        @keyframes scalCenter2 {
          0% {
            transform: scale(0.5);
          }

          100% {
            transform: scale(1);
          }
        }

        .activate {
          // animation: fadeIn 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
          animation: scalCenter2 2s;

          &:hover {
            // width: 70px !important;
            animation: scalCenter2 0.7s ease;
          }
        }
      }

      position: relative;
      bottom: 360px;
      .h1 {
        margin-bottom: 50px;
      }

      // width: 90%;
      // margin-top: 550px;
      // position: relative;
      // text-align: left;
      // padding-left: 5%;
      // padding-top: 5%;
      // bottom: 386px;
      // padding-bottom: 50px;

      .row {
        width: 100%;
        display: flex;
        justify-content: center;

        // align-items: center;

        // flex-direction: row;
        gap: 30px;
        .card-body {
          // box-shadow: 0 0 4px 0px !important;
          padding: 0 !important;
        }

        .one,
        .two,
        .three,
        .four,
        .five {
          // max-height: 700;
          img {
            width: 40%;
            height: 100%;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }
          .card-body {
          }
          .card-footer {
            background-color: white;

            .header {
              margin-bottom: 13px;
              display: block;
              font-size: 18px;
              font-weight: 700;
              color: #011e60;
            }
            .Border_bottom {
            }
          }
        }

        .one,
        .two,
        .three,
        .four,
        .five {
          @media screen and (max-width: 992px) {
            // width: 300px;
            margin-top: 0 !important;
            margin-bottom: 20px;
          }
        }

        .proven_expertise {
          .card {
            .body {
            }
          }
        }

        .track_record_of_success {
          margin-top: 150px;
          // padding: 20px;
        }

        .extensive_network {
          margin-top: 250px;
        }

        .specialist_team {
          // margin-top: 350px;

          margin-top: -230px;
        }

        .high_income_and_asset {
          // margin-top: -280px;
          // margin-top: -233px;
          margin-top: -195px;
          // padding: 100px;
        }

        .low_risk_propositions {
          // margin-top: -133px;

          margin-top: -82px;
        }

        .premier_acquisition {
          margin-top: 0px;
        }
      }
    }

    // .Inner_card_one {
    //   // height: 630px;
    //   background-color: white;
    //   // width: 90%;
    //   padding-left: 5%;
    //   padding-top: 5%;
    //   // margin-top: -310px;
    //   position: relative;
    //   // text-align: left;
    //   bottom: 420px;
    //   // padding: ;
    //   padding: 300px;
    //   padding-top: 120px;

    //   @media screen and (min-width: 1000px) and (max-width: 1400px) {
    //     padding: 150px;
    //   }

    //   @media screen and (min-width: 767px) and (max-width: 1000px) {
    //     padding: 30px;
    //   }

    //   @media screen and (max-width: 767px) {
    //     padding: 0;
    //     padding: 30px;
    //   }

    //   .col-lg-6 {
    //     &:nth-child(odd) {
    //       &.activate {
    //         animation: movetop10 1s;
    //       }

    //       @keyframes movetop10 {
    //         from {
    //           margin-top: 100px;
    //         }

    //         to {
    //           margin-top: 0px;
    //         }
    //       }
    //       // background-color: #fcd002;
    //       .card {
    //         margin-top: 30px;
    //       }
    //     }

    //     &:nth-child(even) {
    //       &.activate {
    //         animation: movetop11 1s;
    //       }

    //       @keyframes movetop11 {
    //         from {
    //           margin-top: 100px;
    //         }

    //         to {
    //           margin-top: 0px;
    //         }
    //       }
    //       // background-color: #011E60;
    //     }
    //   }

    //   .card {
    //     height: 300px;
    //     margin-bottom: 40px;
    //     border-radius: 20px !important;
    //     border: 0px;

    //     &:hover {
    //       transition: all 0.25s;
    //       transform: scale(1.03);
    //     }

    //     // border-bottom: 1px solid;

    //     // @media screen  and (max-width:990px) {
    //     //   width: 500px;
    //     //   float: right;

    //     //  }

    //     // @media screen and (min-width:767px) and (max-width:1200px) {
    //     //   height: 450px;
    //     // }

    //     .card-body {
    //       // width: 300px;
    //       height: 300px;
    //       padding: 0;
    //       margin: 0;

    //       // @media screen and (min-width:767px) and (max-width:1200px) {
    //       //   height: 200px;
    //       // }

    //       img {
    //         height: 300px;
    //         // box-shadow: 0 0 6px 1px;
    //         // border-top-left-radius: 10px;
    //         // border-top-right-radius: 10px;
    //         border-radius: 10px;

    //         // @media screen and (min-width:767px) and (max-width:1200px) {
    //         //   height: 200px;
    //         // }
    //       }
    //     }

    //     .card-footer {
    //       height: 300px;
    //       background-color: rgba(0, 0, 0, 0.5);
    //       color: #fff;
    //       box-shadow: 0 0 4px 1px;
    //       border-radius: 10px;
    //       // margin-left: 50px;
    //       // margin-top: inherit;
    //       position: absolute;

    //       // @media screen and (min-width:767px) and (max-width:1200px) {
    //       //   height: 300px;
    //       // }
    //     }
    //   }

    //   // .card-body {
    //   //   padding: 0;
    //   //   margin: 0;

    //   //   img {
    //   //     width: 100%;
    //   //     height: 100%;
    //   //     border-radius: 0.25rem 0.25rem 0 0;

    //   //   }
    //   // }

    //   h1 {
    //     color: #011e60 !important;
    //   }

    //   p {
    //     padding-right: 5px;
    //   }

    //   @media screen and (min-width: 760px) and (max-width: 1200px) {
    //     // height: 800px;
    //   }

    //   @media screen and (min-width: 510px) and (max-width: 760px) {
    //     // height: 1000px;
    //   }

    //   @media screen and (min-width: 334px) and (max-width: 510px) {
    //     // height: 1300px;
    //   }

    //   @media screen and (min-width: 210px) and (max-width: 334px) {
    //     // height: 1500px;
    //   }
    // }

    .Inner_card_two {
      // height: 800px;
      background-color: white;
      // width: 90%;
      // margin-top: 550px;
      position: relative;
      text-align: left;
      padding-left: 5%;
      padding-top: 5%;
      bottom: 345px;
      padding-bottom: 50px;
      margin-top: 385px;

      .row {
        width: 100%;
      }

      .col-md-6 {
        &:nth-child(2) {
          margin-top: 100px;

          &.activate {
            // animation: movetop1 2s;
          }

          @keyframes movetop1 {
            from {
              margin-top: 200px;
            }

            to {
              margin-top: 100px;
            }
          }

          @media screen and (max-width: 767px) {
            margin-top: 0px;
          }
        }

        &:nth-child(3) {
          margin-top: 200px;

          &.activate {
            // animation: movetop2 2s;
          }

          @keyframes movetop2 {
            from {
              margin-top: 300px;
            }

            to {
              margin-top: 200px;
            }
          }

          @media screen and (max-width: 991px) {
            margin-top: 0px;
          }

          @media screen and (max-width: 767px) {
            margin-top: 0px;
          }
        }

        &:nth-child(4) {
          margin-top: 300px;

          &.activate {
            // animation: movetop4 2s;
          }

          @keyframes movetop4 {
            from {
              margin-top: 400px;
              // opacity: 0;
            }

            to {
              margin-top: 300px;
              // opacity: 1;
            }
          }

          @media screen and (max-width: 991px) {
            margin-top: 100px;
          }

          @media screen and (max-width: 767px) {
            margin-top: 0px;
          }
        }
      }

      .card {
        height: 450px;
        margin-top: 30px;
        box-shadow: 0 0 4px 0px;
        border: 0px;
        c &:hover {
          transition: all 0.25s;
          transform: scale(1.03);
        }

        .card-body {
          height: 150px;
          padding: 0;
          margin: 0;

          @media screen and (min-width: 767px) and (max-width: 1250px) {
            height: 200px;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }

        .card-footer {
          height: 200px;
          background-color: #fff;

          @media screen and (min-width: 767px) and (max-width: 1250px) {
            height: 250px;
          }
        }
      }

      h1 {
        color: #011e60 !important;
      }

      @media screen and (min-width: 760px) and (max-width: 1200px) {
        // margin-top: 400px;
      }

      @media screen and (min-width: 510px) and (max-width: 760px) {
        // margin-top: 670px;
      }

      @media screen and (min-width: 377px) and (max-width: 510px) {
        // margin-top: 768px;
      }

      @media screen and (min-width: 210px) and (max-width: 377px) {
        // margin-top: 990px;
      }

      .Border_bottom {
        // border-bottom: 2px solid #011e60;
        width: fit-content;
        font-weight: bolder;
        font-size: 18px;
        font-weight: 700;
        color: #011e60;
        // color: #011E60 !important;
        // font-weight: 700;
      }

      // ul {
      //   list-style-type:"none";
      //   list-style-image: url('../assets/images/');
      // }
    }
  }

  .boomerang_logo {
    float: left;
    margin-top: 50px;
    margin-left: 30px;
    text-align: left;
    margin-bottom: 70px;
    position: relative;

    p {
      border-bottom: 1px solid #fcd002;
    }
  }

  .Footer_wrapper {
    // margin-top: 300px;
    // margin-top: 120px;
  }
}

@media screen and (min-width: 1000px) {
  .fade_style_wwd {
    // animation: fade_wwd 2s;
  }

  @keyframes fade_wwd {
    from {
      opacity: 0;
      margin-top: 520px;
    }

    to {
      opacity: 1;
      margin-top: 500px;
    }
  }
}

@keyframes keyin {
  0% {
    opacity: 0;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.heroAnimate {
  animation: keyin 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
  // opacity: 0;
  // animation: fade 2s ease-in-out 0s 1 normal forwards;
}

.innovative,
.time,
.holistic,
.Value,
.Property {
  .card {
    // height: 600px;

    height: 500px;

    @media screen and (max-width: 450px) {
      height: 480px;
      margin-bottom: 30px;
    }

    @media screen and (min-width: 250px) and (max-width: 350px) {
      height: 533px;
      margin-bottom: 30px;
    }

    @media screen and (min-width: 450px) and (max-width: 575px) {
      // border: 1px solid green;
      height: 480px;
      margin-bottom: 30px;
    }

    @media screen and (min-width: 575px) and (max-width: 768px) {
      // border: 1px solid green;
      height: 550px;
      margin-bottom: 30px;
    }

    @media screen and (min-width: 992px) and (max-width: 1200px) {
      // border: 1px solid green;
      height: 550px;
      margin-bottom: 30px;
    }
    @media screen and (min-width: 1200px) and (max-width: 1280px) {
      // border: 1px solid green;
      height: 550px;
      margin-bottom: 30px;
    }
    .card-body {
      height: 200px;
      // @media screen and (min-width: 1000px) {
      //   height: 170px;
      // }
    }
    .card-footer {
      height: 370px;
      @media screen and (min-width: 995px) and (max-width: 1200px) {
        height: 450px !important;
      }
      @media screen and (max-width: 767px) {
        height: auto;
      }
    }
    // min-height: 200px;
    // @media screen and (min-width: 1000px) {
    //   height: 170px;
    // }
  }
}

.whatWeDoVideo {
  position: relative;
  padding-top: 55px;
}
.flash_inner_content {
  width: 42%;
  min-width: 325px;
  position: absolute;
  top: 6.5%;
  left: 7%;
  background: white;
  padding: 20px;
  animation: fadeIn 2s ease;
  h1 {
    font-size: 29px;
  }
  @media screen and (max-width: 1148px) {
    top: 2.5%;
  }
  @media screen and (max-width: 1365px) and (min-width: 1165px) {
    top: 2.5%;
  }
  @media screen and (max-width: 1164px) {
    h1 {
      font-size: 22px;
    }
    p {
      font-size: 12px;
      margin: 0;
      margin-bottom: 4px;
    }
  }
  @media screen and (max-width: 910px) {
    top: 1.5%;

    h1 {
      font-size: 16px;
    }
    p {
      font-size: 10px;
      margin: 0;
      margin-bottom: 4px;
    }
  }
  @media screen and (max-width: 650px) {
    top: 70px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
