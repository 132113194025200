/* @import url(./assets/fonts/ProximaNovaFont.otf); */
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;500;600;700&display=swap");
* {
  font-family: "Roboto Slab", serif !important;
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

@font-face {
  /* font-family: "MyFont"; */
  font-family: "Roboto Slab", serif !important;

  src: url("../src/assets/fonts/ProximaNovaFont.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

body::-webkit-scrollbar-thumb {
  /* display: none;  */
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 67, 111, 0.3);
  background-color: #00436f;
}

code {
  /* font-family: Museo Slab 300; */
  font-family: "Roboto Slab", serif !important;
}

p {
  /* font-size: 18px !important; */
}
