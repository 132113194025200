.card_parent_dashboard {
  width: 100%;
  height: 100%;

  .dashboard-inner {
    margin-top: 100px;

    .main_contact_wrapper {
      @media screen and (min-width: 701px) and (max-width: 1200px) {
        // top: 74px !important;
        padding: 0%;
        // height: 437px;
      }

      @media screen and (max-width: 700px) {
        // top: 54px !important;
        padding: 0%;
        // height: 337px;
      }
    }

    .container {
      .row {
        .card-wrap {
          margin-bottom: 50px;
          .card {
            text-align: center;
            box-shadow: rgb(0, 0, 0) 0px 0px 13px 0px;
            cursor: pointer;         
            transition: transform 0.3s ease; /* Added transition for smooth effect */

            &:hover {
              transform: scale(1.05); /* Zoom in effect on hover */
            }
            
            @media screen and (min-width: 768px) and (max-width: 1200px) {
              height: 100%;
            }

            .card-body {
              cursor: pointer;
              .card-top {
                width: 100%;
                height: 100%;
                margin-top: 20px;
                margin-bottom: 20px;
                cursor: pointer;

                @media screen and (min-width: 768px) and (max-width: 1200px) {
                height: 13%;
               
                cursor: pointer;
                }

                .card-topic {
                  width: 100%;
                  // margin: 0 auto;
                  cursor: pointer;

                  .card-heading-one {
                    color: #011e60;
                    display: block;
                    font-size: 18px;
                    font-weight: 700;
                    margin-bottom: 13px;
                    cursor: pointer;
                  }
                  .card-heading-two {
                    color: #011e60;
                    display: block;
                    font-size: 28px;
                    font-weight: 700;
                    margin-bottom: 13px;
                    cursor: pointer;
                  }
                }

                .underline {
                  border: 2px solid #ffda00;
                  width: 25%;
                  margin: 0 auto;
                  border-radius: 10px;
                  margin-top: 10px;
                  margin-bottom: 10px;
                }
              }
              .card-body {
                cursor: pointer;
                .text {
                  border: 1px solid;
                  cursor: pointer;

                  p {
                    text-align: justify;
                    cursor: pointer;
                  }
                }
              }
            }
          }

          @keyframes scalCenter {
            0% {
              transform: scale(0.5);
            }

            100% {
              transform: scale(1);
            }
          }

          .activate {
            // animation: fadeIn 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
            animation: scalCenter 2s;

           

            &:hover {
              // width: 70px !important;
              animation: scalCenter 0.7s ease;
            }
          }
        }
      }
    }
  }
}

@keyframes projectBanner {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.main_contact_wrapper {
  animation: projectBanner 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}
