.loader_main{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0,0,0,0.5);

    .loaderimg{
        position: absolute;
        left: 50%;
        top: 47%;
        width: 68px;    
        background-color: transparent;
        @media screen and (max-width:400px) {
            width: 120px;
            left: 38%;
            
        }


    }
}



.loader_main_card{
    position: absolute;
    width: 100%;
    height: 51%;
    top: 0;
    left: 0;
    z-index: 999;
    // background-color: rgba(0,0,0,0.5);

    @media screen and (max-width:770px) {
        height: 70%;

    }

    .loaderimg_card{
        position: absolute;
        left: 50%;
        top: 47%;
        width: 68px;    
        background-color: transparent;
        @media screen and (max-width:400px) {
            width: 120px;
            left: 38%;
            
        }


    }
}