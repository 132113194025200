.out_team_main {
  position: relative;
  bottom: 0;
  // margin-bottom: -100px;

  // margin-top: 1790px;
  margin-top: 1630px;

  @media screen and (min-width: 1300px) and (max-width: 1400px) {
    // margin-top: 2000px !important;
    margin-top: 1850px !important;
  }

  @media screen and (min-width: 1500px) and (max-width: 1700px) {
    // margin-top: 2000px !important;
    margin-top: 1700px !important;
  }


  @media screen and (min-width: 1400px) and (max-width: 1500px) {
    // margin-top: 2000px !important;
    margin-top: 1750px !important;
  }

  @media screen and (min-width: 1000px) and (max-width: 1200px) {
    // margin-top: 2595px !important;
    margin-top: 2000px !important;
  }

  @media screen and (min-width: 1200px) and (max-width: 1300px) {
    // margin-top: 2595px !important;
    margin-top: 1900px !important;
  }

  // @media screen and (min-width: 767px) and (max-width: 999px) {
  //   margin-top: 3050px !important;
  // }
  @media screen and (min-width: 902px) and (max-width: 999px) {
    margin-top: 2300px !important;
  }

  @media screen and (min-width: 785px) and (max-width: 901px) {
    // margin-top: clamp(2775px, 50vw, 3200px) !important;
    // margin-top: 2500px !important;
    margin-top: 2300px !important;

  }
 
  @media screen and (min-width: 768px) and (max-width: 784px) {
    // margin-top: clamp(2775px, 50vw, 3200px) !important;
    // margin-top: 2600px !important;
margin-top: 2300px !important;
  }
  @media screen and (min-width: 760px) and (max-width: 768px) {
    // margin-top: clamp(2775px, 50vw, 3200px) !important;
    // margin-top: 3000px !important;
    margin-top: 2880px !important;
  }
  @media screen and (min-width: 720px) and (max-width: 760px) {
    // margin-top: clamp(2775px, 50vw, 3200px) !important;
    // margin-top: 3681px !important;
    margin-top: 3000px !important;
  }
  @media screen and (min-width: 577px) and (max-width: 720px) {
    // margin-top: clamp(2775px, 50vw, 3200px) !important;
    margin-top: 2870px !important;
  }
  // @media screen and (min-width: 577px) and (max-width: 893px) {
  //   // margin-top: clamp(2775px, 50vw, 3200px) !important;
  //   margin-top: 3200px !important;
  // }

  // @media screen and (min-width: 500px) and (max-width: 575px) {
  //   // margin-top: 1850px !important;
  //   margin-top: 4690px !important;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   flex-direction: column;
  // }

  @media screen and (min-width: 575px) and (max-width: 576px) {
    margin-top: 2900px !important;


  }

  @media screen and (min-width: 500px) and (max-width: 575px) {
    // margin-top: 5390px !important;
    // margin-top:2900px !important;
    margin-top: 3370px !important;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  @media screen and (min-width: 450px) and (max-width: 500px) {
    // margin-top: 4200px !important;
    margin-top: 3400px !important;
  }
  @media screen and (min-width: 350px) and (max-width: 450px) {
    // margin-top: 4200px !important;
    margin-top: 4100px !important;
  }

  @media screen and (max-width: 350px) {
  
    // margin-top: 4200px !important;
    margin-top: 5850px !important;
  }
  // @media screen and (max-width: 499px) {
  //   // margin-top: 4842px !important;
  //   // margin-top: 5390px !important;
  //   // margin-top: 4200px !important;
  //   margin-top: 5500px !important;
  // }

  // margin-top: 5380px !important;

  // @media screen and (min-width: 900px) and (max-width: 1000px) {
  //   // margin-top: 1844px !important;
  //   margin-top: 2050px !important;
  // }

  // @media screen and (min-width: 200px) and (max-width: 700px) {
  //   // margin-top: 2900px !important;
  //   margin-top: 4100px !important;
  // }

  .head-team {
    padding-left: 10px;
    // // font: 50px "Museo Slab 700";
    font-size: 50px;
    font-weight: 700;
    color: #011e60;
    padding: 2.5rem 4.2rem 3rem 0rem;
  }

  .img-cont {
    display: flex;
    // @media screen and (min-width: 767px) and (max-width: 992px) {
    //   gap: 50px;
    // }
    @media screen and (max-width: 575px) {
      width: 242px;
    }
    .row {
      @media screen and (min-width: 767px) and (max-width: 992px) {
        gap: 50px;
      }

      .img_card {
        @media screen and (min-width: 767px) {
          margin-right: 70px;
        }

        .img_block {
          img {
            width: 180px;
            height: 168px;
          }

          .text-below {
            padding-top: 20px;
            margin-right: -83px;
            padding-bottom: 20px;
            width: 208px;

            .name {
              // font: 20px "Museo Slab 500";
              font-weight: 500;
              font-size: 20px;
              font-weight: 500;
              display: block;
            }

            .designation {
              font-size: 15px;
              font-weight: 300;
              // font-weight: 300;
              // font: 15px "Museo Slab 300";
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .fade_style_ot {
    // animation: fade 1s;
  }

  @keyframes fade {
    from {
      opacity: 0;
      margin-top: 1450px;

      @media screen and (min-width: 680px) and (max-width: 900px) {
        margin-top: 1225px !important;
      }

      @media screen and (min-width: 900px) and (max-width: 1000px) {
        margin-top: 1340px !important;
      }

      @media screen and (min-width: 200px) and (max-width: 700px) {
        margin-top: 1290px !important;
      }
    }

    to {
      opacity: 1;
      margin-top: 1350px;

      @media screen and (min-width: 680px) and (max-width: 900px) {
        margin-top: 1205px !important;
      }

      @media screen and (min-width: 900px) and (max-width: 1000px) {
        margin-top: 1320px !important;
      }

      @media screen and (min-width: 200px) and (max-width: 700px) {
        margin-top: 1270px !important;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes scalCenter {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.activate {
  // animation: fadeIn 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
  animation: scalCenter 1s ease 0s 1 normal forwards;
}
