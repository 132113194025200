.Home_wrapper {
  height: 100vh;
  position: relative;
  // padding-left: 5%;
  // padding-right: 5%;
  text-align: center;
  font-family: "Roboto Slab", serif !important;

  
  @media screen and (max-width: 1300px) {
    padding: 0%;
  }

  .Home_outer_wrpper {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // height: auto;
    // width: 112%;
    // margin-left: -58px;
  }

  .Home_inner_warpper {
    // opacity: 0; /* Initially hidden */
    // transform: scale(1); /* Slightly larger size */
    // animation: fadeInScale 1.5s ease-in-out forwards;
    display: flex;
    justify-content: flex-end;
    // @keyframes fadeInScale {
    //   0% {
    //     opacity: 0;
    //     transform: scale(1.1); /* Start slightly larger */
    //   }
    //   100% {
    //     opacity: 1; /* Fully visible */
    //     transform: scale(1); /* Return to normal size */
    //   }
    // }
    // background-image: url(../assets/images/large/boomerang-home-flash.jpg);
    // background-image: url(../assets/sprite/ezgif2.png);
    // background-color: #fee600;

    height: auto;
    // height: 705px;
    // width: 1200px;
    // height: 700px;
    // position: relative;
    width: 103%;
    // background-size: cover;
    // top: 60px;
    // background-position: -117600px;
    // background-repeat: no-repeat;
    // animation: test 5s steps(98) forwards;

    // margin-top: 69px;
    margin-top: 60px;

    @keyframes test {
      from {
        background-position: 0px;
      }

      to {
        background-position: -117600px;
      }
    }
    //   @media screen and (min-width: 260px) and (max-width: 500px) {
    //  flex-direction: column;
    //  justify-content: center;
    //  align-items: center;

    //   }

    @media screen and (min-width: 950px) and (max-width: 1100px) {
      // height: 316px;
      // position: relative;
      // width: 100%;
      // background-size: contain;
      // top: 50px;
      // margin-top: 69px;
      margin-top: 49px;

      margin-bottom: 260px;
    }

    @media screen and (min-width: 1105px) and (max-width: 1280px) {
      // margin-top: 69px;
      margin-top: 49px;
    }
  }

  .Home_banner_textcontainer {
    opacity: 0;
    /* Initially hidden */
    transform: scale(1.05);
    /* Slightly larger size */
    animation: fadeInScale 1.5s ease-in-out forwards;

    @keyframes fadeInScale {
      0% {
        opacity: 0;
        transform: scale(1.1);
        /* Start slightly larger */
      }

      100% {
        opacity: 1;
        /* Fully visible */
        transform: scale(1);
        /* Return to normal size */
      }
    }

    width: 650px;
    margin: auto;
    // background-color: rgba(0, 0, 0, 0.4);
    position: absolute !important;
    z-index: 7;
    background-color: #fff;
    height: 390px;
    position: relative;
    float: right;
    padding: 60px 70px 34px 49px;
    text-align: left;
    top: 87px;
    box-shadow: 0 0 4px -1px goldenrod;
    // color: #fff;

    .unlock {
      transition: 0.7s;
      font-weight: 500;
      text-decoration: underline;

      &:hover {
        transition: 1s;
        text-decoration: underline;
        // font-weight: 600;
        background-color: #fee600;
        // transition: 0.5s;
      }
    }

    h1 {
      color: #011e60;
      font-weight: 700;
      // font-size: 35px;
      text-transform: uppercase;
      font-family: "Roboto Slab", serif !important;
    }

    p {
      font-size: 16px;
    }

    @media screen and (max-width: 1320px) {
      padding: 32px 27px 29px 22px;
      height: 376px;
      top: 47px;
    }

    @media screen and (max-width: 1230px) {
      padding: 32px 27px 29px 22px;
      height: 355px;
    }

    @media screen and (max-width: 1180px) {
      padding: 32px 27px 29px 22px;
      height: 335px;
      width: 422px;

      h1 {
        font-size: 34px;
      }
    }

    @media screen and (max-width: 1100px) {
      padding: 32px 27px 29px 22px;
      height: 285px;
      width: 427px;
      //  margin-right: 61px;
      margin-top: -7px;

      h1 {
        font-size: 24px;
      }
    }

    @media screen and (max-width: 1065px) {
      padding: 32px 27px 29px 22px;
      height: 285px;
      width: 417px;

      h1 {
        font-size: 24px;
      }
    }

    @media screen and (max-width: 980px) {
      padding: 32px 27px 29px 22px;
      height: 285px;
      width: 382px;
      top: 33px;
    }

    @media screen and (max-width: 910px) {
      padding: 32px 27px 29px 22px;
      height: 277px;
      width: 418px;
    }

    @media screen and (max-width: 880px) {
      padding: 20px 24px 29px 22px;
      height: 250px;
      width: 331px;

      h1 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
      }
    }

    @media screen and (max-width: 810px) {
      padding: 20px 24px 29px 22px;
      height: 228px;
      width: 332px;
    }

    @media screen and (min-width: 580px) and (max-width: 730px) {
      padding: 20px 24px 29px 22px;
      height: 186px;
      width: 322px;
      top: 30px;

      h1 {
        font-size: 15px;
      }

      p {
        font-size: 12px;
      }
    }

    @media screen and (max-width: 580px) {
      // margin-top: 25%;
      height: 167px;
      width: 314px;
      top: 28px;

      h1 {
        font-size: 15px;
      }

      p {
        font-size: 12px;
      }
    }

    @media screen and (min-width: 490px) and (max-width: 540px) {
      height: 147px;
      width: 294px;
      top: 29px;

      h1 {
        font-size: 12px;
      }

      p {
        font-size: 10px;
      }
    }

    @media screen and (min-width: 390px) and (max-width: 490px) {
      height: 117px;
      width: 228px;
      top: 27px;
      padding: 13px 17px 22px 16px;

      h1 {
        font-size: 10px;
      }

      p {
        font-size: 8px;
      }
    }

    @media screen and (min-width: 320px) and (max-width: 390px) {
      height: 90px;
      width: 186px;
      top: 30px;
      padding: 13px 17px 22px 16px;

      h1 {
        font-size: 8px;
      }

      p {
        font-size: 6px;
      }
    }

    @media screen and (max-width: 320px) {
      height: 78px;
      width: 153px;
      top: 28px;
      padding: 9px 17px 21px 11px;

      h1 {
        font-size: 7px;
      }

      p {
        font-size: 5px;
      }
    }
  }

  .Home_second_container {
    // height: 70vh;
    opacity: 0;
    transform: scale(1.05);
    animation: fadeInScale 2s ease-in-out forwards;
    position: relative;
    margin-top: 5%;
    padding: 2%;

    margin-bottom: 100px;

    @media screen and (min-width: 650px) and (max-width: 768px) {
      // border: 1px solid;
      margin-bottom: 250px;
    }

    // @media screen and (min-width: 250px) and (max-width: 300px) {
    //   // border: 1px solid;
    //   height: 83vh;
    // }
    // @media screen and (min-width: 300px) and (max-width: 400px) {
    //   // border: 1px solid;
    //   height: 60vh;
    // }
    // @media screen and (min-width: 400px) and (max-width: 550px) {
    //   // border: 1px solid;
    //   height: 65vh;
    // }
    // @media screen and (min-width: 550px) and (max-width: 650px) {
    //   // border: 1px solid brown;
    //   height: 60vh;
    // }
    // @media screen and (min-width: 650px) and (max-width: 700px) {
    //   // border: 1px solid yellow;
    //   height: 78vh;
    // }
    // @media screen and (min-width: 701px) and (max-width: 701px) {
    //   // border: 1px solid gold;
    //   height: 78vh;
    // }
    // @media screen and (min-width: 701px) and (max-width: 768px) {
    //   // border: 1px solid red;
    //   height: 50vh;
    // }
    // @media screen and (min-width: 768px) and (max-width: 992px) {
    //   border: 2px solid red;
    //   // height: 90vh;
    //   height: 59vh;
    // }
    // @media screen and (min-width: 992px) and (max-width: 1200px) {
    //   // border: 1px solid blue;
    //   // height: 75vh;
    //   height: 75vh;
    // }

    @keyframes fadeInScale {
      0% {
        opacity: 0;
        transform: scale(1.1);
        /* Start slightly larger */
      }

      100% {
        opacity: 1;
        /* Fully visible */
        transform: scale(1);
        /* Return to normal size */
      }
    }

    .content {
      text-align: justify;

      &.active {
        // animation: left_move 0.5s;
      }

      @keyframes left_move {
        from {
          left: 0;
        }

        to {
          left: 100%;
        }
      }

      @media screen and (min-width: 700px) and (max-width: 1070px) {
        margin-top: -10%;
      }

      @media screen and (min-width: 589px) and (max-width: 700px) {
        margin-top: 90px;
      }

      @media screen and (min-width: 501px) and (max-width: 589px) {
        margin-top: 10%;
      }

      @media screen and (max-width: 500px) {
        margin-top: -33px;
      }
    }

    .row {
      width: 100% !important;
      margin: 0;
    }

    @media screen and (min-width: 1000px) and (max-width: 1100px) {
      margin-top: -15%;
    }

    @media screen and (min-width: 900px) and (max-width: 1000px) {
      margin-top: 15%;
    }

    @media screen and (min-width: 800px) and (max-width: 900px) {
      margin-top: 15%;
    }

    @media screen and (min-width: 700px) and (max-width: 800px) {
      margin-top: 15%;
    }

    @media screen and (min-width: 600px) and (max-width: 700px) {
      margin-top: -10%;
    }

    @media screen and (min-width: 300px) and (max-width: 500px) {
      margin-top: 12%;
    }

    @media screen and (min-width: 501px) and (max-width: 580px) {
      margin-top: 0%;
    }

    @media screen and (min-width: 200px) and (max-width: 400px) {
      margin-top: 20%;
    }

    // .observer_content1 {
    //   &.active {
    //     animation: left_move .5s;
    //   }

    //   @keyframes left_move {
    //     from {
    //       left: 0;
    //     }

    //     to {
    //       left: 100%;
    //     }
    //   }
    // }

    .video_img {
      // background-image: url(../assets/images/small/video-place-holder.jpg);
      position: relative;
      background-repeat: no-repeat;
      background-size: contain;
      width: 100%;
      height: 400px;

      .video_src {
        width: 100%;
        margin-bottom: 20px;
        border-radius: 0.75rem;

        // height: 100%;
        @media screen and (min-width: 670px) and (max-width: 800px) {
          height: 400px;
        }

        @media screen and (min-width: 280px) and (max-width: 669px) {
          height: 250px;
        }

        @media screen and (min-width: 200px) and (max-width: 280px) {
          height: 170px;
        }
      }

      .logo_src {
        width: 60%;
        margin-top: 50px;
      }
    }

    .content {
      padding-right: 3%;
      padding-left: 3%;
      margin-bottom: 10px;

      .card {
        border: none;
      }
      p {
        margin-bottom: 30px;
        font-size: 16px;

        color: #011e60;
      }
    }
  }

  .Home_third_component {
    // margin-top: 300px;
    // border: 1px solid green;
    overflow-x: hidden;

    .Header {
      margin: 10px;
      font-weight: 700;
    }

    @media screen and (max-width: 910px) {
      // margin-top: 380px;
      margin-top: 100px;
    }

    // @media screen and (max-width: 810px) {
    //   margin-top: 580px;
    // }

    // @media screen and (max-width: 765px) {
    //   margin-top: 1030px;
    // }
    // @media screen and (max-width: 400px) {
    //   margin-top: 1350px;
    // }

    // @media screen and (max-width: 315px) {
    //   margin-top: 1560px;
    // }

    // @media screen and (max-width: 250px) {
    //   margin-top: 1800px;
    // }

    .buytoletinvestment {
      margin-top: 100px;
      margin-bottom: 100px;
      // background-image: url(../assets/images/large/house-bg.jpg);
      position: relative;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 100%;
      min-height: 800px;
      padding-bottom: 20px;
      background: #ffda00;

      @media screen and (max-width: 1040px) {
        // margin-top: 302px;
        // margin-top: 442px;

        // height: 1700px;
        height: auto;
        // display: flex;
        // justify-content: center;

        .asset_boost {
          //   width: ;
          padding: 30px;
          background-color: white;
          margin: 30px;
          width: 100% !important;

          // margin-left: 30px;
          // margin-left: 320px;
          // height: 593px;
        }

        .property_market {
          padding: 30px;
          background-color: white;
          width: 100% !important;
          margin: 30px;
        }

        .safeinvestment {
          padding: 30px;
          background-color: white;
          width: 100% !important;
          margin: 30px;
        }
      }

      @media screen and (max-width: 450px) {
        // margin-top: 450px;
      }

      @media screen and (min-width: 767px) and (max-width: 1040px) {
        margin-top: 40px !important;
      }

      h1 {
        // color: white;
        color: #011e60;
        padding-top: 70px;
        padding-bottom: 50px;
        font-size: clamp(0.5rem, 10vw, 3rem);

        // font-family: "Museo Slab 700";
      }

      .three_cards {
        display: flex;
        justify-content: center;
        // align-items: center;
        width: 80% !important;
        margin-top: 5vw;
        margin-left: 10%;
        gap: 20px;
      }

      .asset_boost {
        //   width: ;
        padding: 30px;
        background-color: white;
        // margin-left: 320px;
        // height: 593px;
        position: relative;
        border-radius: 0.75rem;
        transition: 0.7s;

        // &:hover {
        //   margin-right: 50px;
        //   transform: scale(1.5);
        //   transition: 0.7s;
        //   margin-left: 5px;
        // }

        svg {
          width: 100px;
          height: 100px;
          color: #011e60;

        }
      }

      .property_market {
        padding: 30px;
        background-color: white;
        margin-left: 20px;
        border-radius: 0.75rem;
        transition: 0.7s;

        // &:hover {
        //   margin-right: 50px;
        //   margin-left: 50px;

        //   transform: scale(1.5);
        //   transition: 0.7s;
        // }
        // height: 593px;
        svg {
          width: 100px;
          height: 100px;
          color: #011e60;

        }
      }

      .safeinvestment {
        padding: 30px;
        background-color: white;
        margin-left: 20px;
        border-radius: 0.75rem;
        transition: 0.7s;

        // height: 593px;
        // &:hover {
        //   // margin-right: 50px;
        //   margin-left: 50px;
        //   transition: 0.7s;

        //   transform: scale(1.1);
        // }
        svg {
          width: 100px;
          height: 100px;
        color: #011e60;
        }
      }
    }
  }

  .Home_fourth_component {
    // margin-top: 100px;
    // margin-top: 0px;

    // @media screen and (max-width: 768px) {
    //   // margin-top: 420px;
    //   margin-top: 800px;
    // }

    // @media screen and (min-width: 768px) and (max-width: 800px) {
    //   margin-top: 400px;
    //   border: 2px solid red;
    // }

    // @media screen and (min-width: 800px) and (max-width: 992px) {
    //   margin-top: 200px;
    // }

    // border: 1px solid red;
    overflow-x: hidden;

    .Header {
      font-size: 20px;
      font-weight: 700;
    }

    .observer_content {
      &.active {
        // .active {
        // animation: lefttoright 2s;
        // }

        // .active1 {
        //   animation: righttoleft .5s;
        // }
      }
    }

    .observer_content1 {
      &.active {
        // .active {
        // animation: righttoleft 2s;
        // }

        // .active1 {
        //   animation: righttoleft .5s;
        // }
      }
    }

    .observer_content2 {
      &.active {
        // .active {
        // animation: lefttoright 2s;
        // }

        // .active1 {
        //   animation: righttoleft .5s;
        // }
      }
    }

    .observer_content3 {
      &.active {
        // .active {
        // animation: righttoleft 2s;
        // }

        // .active1 {
        //   animation: righttoleft .5s;
        // }
      }
    }

    .observer_content4 {
      &.active {
        // .active {
        // animation: lefttoright 2s;
        // }

        // .active1 {
        //   animation: righttoleft .5s;
        // }
      }
    }

    .observer_content5 {
      &.active {
        // .active {
        // animation: righttoleft 2s;
        // }

        // .active1 {
        //   animation: righttoleft .5s;
        // }
      }
    }

    @keyframes lefttoright {
      from {
        margin-left: -500px;
      }

      to {
        margin-left: 0px;
      }
    }

    @keyframes righttoleft {
      from {
        margin-left: 500px;
      }

      to {
        margin-left: 0px;
      }
    }

    .Header {
      // font-weight: 900;
      color: #011e60;
      // font-family: "Museo Slab 700";
    }

    .Gridview {
      // width: 96%;
      .row {
        width: 100%;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 576px) {
          justify-content: center;
          padding-right: 0 !important;
          margin-right: 0 !important;
        }

        p {
          @media screen and (max-width: 576px) {
            width: 80vw;
            margin: 10px 0 20px 0;
          }
        }
      }
    }

    .col-lg-3 {
      background-color: white;

      img {
        width: 100%;
        height: 100%;

        @media screen and (max-width: 767px) {
          width: 70%;
        }
      }

      p {
        margin-top: 5px;
      }
    }

    .inner_content {
      @media screen and (min-width: 500px) and (max-width: 767px) {
        width: 60%;
      }

      @media screen and (min-width: 100px) and (max-width: 500px) {
        width: 90%;
      }
    }

    .col-md-4 {
      background-color: white;

      p {
        margin-top: 5px;
      }
    }
  }
}

.active1 {
  // animation: mymove 2s;
}

@keyframes mymove {
  from {
    left: -357px;
  }

  to {
    left: 0px;
  }
}

.active3 {
  // animation: mymove2 2s;
}

@keyframes mymove2 {
  from {
    left: 357px;
  }

  to {
    left: 0px;
  }
}

.active2 {
  // animation: mymove3 2s;
  transform-style: preserve-3d;
}

@keyframes mymove3 {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
}

@media screen and (min-width: 1000px) {
  .fade_style1 {
    // animation: fade1 1s;
  }

  .fade_style2 {
    // animation: fade2 1s;
  }

  .fade_style3 {
    // animation: fade3 1s;
  }

  @keyframes fade1 {
    from {
      opacity: 0;
      margin-top: 16%;
    }

    to {
      opacity: 1;
      margin-top: 14%;
    }
  }

  @keyframes fade2 {
    from {
      opacity: 0;
      margin-top: 4%;
    }

    to {
      opacity: 1;
      margin-top: 0px;
    }
  }

  @keyframes fade3 {
    from {
      opacity: 0;
      margin-top: 120px;
    }

    to {
      opacity: 1;
      margin-top: 100px;
    }
  }
}

.scale {
  transition: all 0.3s;
  transform: scale(1);
  display: block;

  &:hover {
    transform: scale(1.1);
  }
}

.gridimg {
  @media screen and(max-width:790px) {
    display: flex;
    justify-content: center;
  }
}

// .gap {
//   gap: 10px;
// }
/* Define the animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-250px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideBottom {
  0% {
    opacity: 0;
    transform: translateY(250px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply the animation to the .animate class */
.Home_second_container.animate {
  animation: fadeIn 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
  // animation: fade 2s ease-in-out 0s 1 normal forwards;
}

.slideLeft {
  // transition-delay: 2s;
  animation: slideInFromLeft 2s ease-in-out forwards;
}

.slideTop {
  // transition-delay: 2s;

  // animation: fadeIn 1.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
  animation: slideInFromTop 2s ease-in-out forwards;
}

.slideRight {
  // transition-delay: 2s;
  animation: slideInFromRight 2s ease-in-out forwards;
}

.buytoletinvestment.animate {
  animation: fadeIn 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

.h1.animate {
  // animation: fadeIn 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
  animation: slideInFromLeft 1s ease-in-out forwards;
}

// .left.animate {
//   animation: slideInFromLeft 1s ease-in-out forwards;
// }
// .right.animate {
//   // transition-delay: 2s;
//   animation: slideInFromRight 1s ease-in-out forwards;
// }

.left {
  animation: slideInFromLeft 1s ease-in-out forwards;
}

.right {
  // transition-delay: 2s;
  animation: slideInFromRight 1s ease-in-out forwards;
}
