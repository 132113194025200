.card_parent {
  width: 100%;
  height: 100%;
  position: relative;
  top: 115px;
  font-family: Effra-Medium, Arial, Geneva, sans-serif;

  margin-bottom: 120px;
  &.active {
    margin-top: 150px;
  }

  @media screen and (max-width: 1111px) {
    top: 140px;
  }

  .main_content {
    font-size: 16px;
    margin-top: 5px;
    padding: 10px;

    @media screen and (max-width: 750px) {
      padding: 12px;
      margin-top: 21px;
    }

    @media screen and (max-width: 900px) {
      margin-top: 0px;
    }

    .count {
      display: flex;
      align-items: baseline;
    }
  }

  .property_price {
    background-color: #f4f4f5;
    border-right: 1px solid #dfdfe1;
    height: 100%;
    border-radius: 0px 0px 0px 10px;

    @media screen and (max-width: 770px) {
      border-radius: 0px 0px 0px 0px;
    }

    .price {
      min-height: 33px;
      font-size: 25px;
      font-weight: 700;
      line-height: 1;
      padding: 10px;
      margin: 0;
      color: #57545f;
    }

    .offer {
      font-size: 25px;
      font-weight: 300;
      line-height: 1;
      padding: 5px 10px;
      margin: 0;
    }
  }

  .contact {
    // padding: 13px 0px 13px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // margin-top: 58px;
    @media screen and (max-width: 750px) {
      margin-top: 0px;
    }

    .content {
      display: flex;
      align-items: center;

      .footer_contact {
        display: flex;
        margin-left: 20px;
        align-items: center;

        @media screen and (max-width: 800px) {
          > p {
            display: none;
          }
        }
      }
    }
  }

  .save {
    width: 15%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 10px;

    @media screen and (max-width: 450px) {
      > p {
        display: none;
      }
    }
  }
}

.carousel-item.active {
  height: 100%;
  // display: flex;
}

.prev {
  background-image: linear-gradient(-90deg, transparent, #262637);
  top: 0 !important;
  height: 100% !important;
  border-radius: 10px 0px 0px 0px;

  @media screen and (max-width: 770px) {
    border-radius: 10px 0px 0px 0px;
  }
}

.next {
  background-image: linear-gradient(90deg, transparent, #262637);
  top: 0 !important;
  height: 100% !important;

  @media screen and (max-width: 770px) {
    border-radius: 0px 10px 0px 0px;
  }
}

.carousel-inner .carousel-item {
  transition: -webkit-transform 2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.carousel-inner {
  @media screen and (max-width: 770px) {
    border-radius: 10px 10px 0px 0px;
  }
}

.nodata {
  width: 100%;
  justify-content: center;
  font-size: 25px;
}

.property_header {
  margin: 25px;
  color: #011e60;
  // display: none;
  // transition: all 1s;
  font-size: 40px !important;
  font-family: "Roboto Slab", serif !important;
  font-size: 40px !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  animation: mymovess 2s;

  @keyframes mymovess {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  h1 {
  }
}

.rounded {
  display: none;
}

.offer_tag {
  // width: 214px;
  // height: 70px;
  position: absolute;
  background-color: green;
  border-radius: 10px;
  // margin: 10px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  padding: 10px;

  @media screen and (max-width: 800px) {
    margin-left: 12px;
  }
}

.new_card {
  //   margin-bottom: 40px;
  border-radius: 10px;
  cursor: pointer;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: none;
  border-radius: 10px 0px 0px 10px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);

  .carousel {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
  }

  .prev3 {
    background-image: linear-gradient(-90deg, transparent, #262637);
    top: 0 !important;
    height: 100% !important;
    border-radius: 10px;

    // @media screen and (max-width: 770px) {
    //     border-radius: 10px 0px 0px 0px;

    // }
  }

  .next3 {
    background-image: linear-gradient(90deg, transparent, #262637);
    top: 0 !important;
    height: 100% !important;
    border-radius: 10px;

    @media screen and (max-width: 780px) {
      border-radius: 10px;
    }
  }

  .prev1 {
    background-image: linear-gradient(-90deg, transparent, #262637);
    top: 0 !important;
    height: 100% !important;
    border-radius: 10px;
  }

  .next1 {
    background-image: linear-gradient(90deg, transparent, #262637);
    top: 0 !important;
    height: 100% !important;
    border-radius: 10px;
  }
}

.right_card_wrap {
  // box-shadow: 10px 10px 35px 0px rgba(0, 0, 0, 0.75) !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 0.75rem;
  padding-left: 26px !important;

  .right_card_pc {
    // margin-top: 43px;
    // margin-left: 25px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    // font-size: 5px;

    @media screen and (max-width: 920px) {
      padding: 20px 0 20px 20px;
      margin-left: 0px;
    }

    .one .two .three .four {
      width: 320px;
      height: auto;
      margin-bottom: 3px;
      text-align: center;
      padding: 5px;
      span {
        color: black;
        font-size: 20px;
      }
      @media screen and (max-width: 780px) {
        margin-bottom: 10px;
        width: 70%;
        margin-left: 0 !important;

        &:hover {
          transform: scale(1) !important;
          margin-bottom: 10px !important;
          margin-left: 0 !important;
        }
      }

      &:hover {
        // padding-left: 20px;
        // color: green;
        // transform: scale(1.01);
        // animation: shake 0.7s;
      }
    }

    // .two {
    //   // width: 320px;
    //   // height: 55px;
    //   // border-radius: 10px;
    //   // padding: 5px;
    //   // background-color: #fafad2;
    //   // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    //   // border: 1px solid #ddd;
    //   // margin-bottom: 3px;
    //   // text-align: center;
    //   // margin-left: 43px;
    //   // animation: mymovetwo 3s;
    //   // border-radius: 0px 50px 0px 50px;

    //   @media screen and (max-width: 780px) {
    //     border-radius: 50px 50px 50px 50px;
    //     margin-bottom: 10px;
    //     width: 80%;
    //     margin-left: 0 !important;

    //     &:hover {
    //       transform: scale(1) !important;
    //       margin-bottom: 10px !important;
    //       margin-left: 0 !important;
    //     }
    //   }

    //   &:hover {
    //     transform: scale(1.01);
    //     // animation: shake .7s;
    //   }
    // }

    // .three {
    //   // width: 320px;
    //   // height: 55px;
    //   // border-radius: 10px;
    //   // padding: 5px;
    //   // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    //   // border: 1px solid #ddd;
    //   // margin-bottom: 3px;
    //   // margin-left: 83px;
    //   // text-align: center;
    //   // animation: mymovethree 3s;
    //   // background-color: #ffe4e1;
    //   // border-radius: 0px 50px 0px 50px;

    //   @media screen and (max-width: 780px) {
    //     border-radius: 50px 50px 50px 50px;
    //     margin-bottom: 10px;
    //     width: 90%;
    //     margin-left: 0 !important;

    //     &:hover {
    //       transform: scale(1) !important;
    //       margin-bottom: 10px !important;
    //       margin-left: 0 !important;
    //     }
    //   }

    //   &:hover {
    //     transform: scale(1.01);
    //     // animation: shake .7s;
    //   }
    // }

    // .four {
    //   // width: 320px;
    //   // height: 55px;
    //   // border-radius: 10px;
    //   // padding: 5px;
    //   // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    //   // border: 1px solid #ddd;
    //   // margin-bottom: 3px;
    //   // margin-left: 124px;
    //   // text-align: center;
    //   // animation: mymove4 3s;
    //   // background-color: #cdc5bf;
    //   // border-radius: 0px 50px 0px 50px;

    //   @media screen and (max-width: 780px) {
    //     border-radius: 50px 50px 50px 50px;
    //     margin-bottom: 10px;
    //     width: 100%;
    //     margin-left: 0 !important;

    //     &:hover {
    //       transform: scale(1) !important;
    //       margin-bottom: 10px !important;
    //       margin-left: 0 !important;
    //     }
    //   }

    //   &:hover {
    //     transform: scale(1.01);
    //     // animation: shake .7s;
    //   }
    // }
  }
}

@keyframes shake {
  from {
    transform: rotateX(0deg);
  }

  to {
    transform: rotateX(360deg);
  }
}

@keyframes mymove4 {
  from {
    transform: rotateX(0deg);
    width: 0px;
  }

  to {
    transform: rotateX(360deg);
    width: 320px;
  }
}

@keyframes mymovethree {
  from {
    transform: rotateX(0deg);
    width: 0px;
  }

  to {
    transform: rotateX(360deg);
    width: 320px;
  }
}

@keyframes mymovetwo {
  from {
    transform: rotateX(0deg);
    width: 0px;
  }

  to {
    transform: rotateX(360deg);
    width: 320px;
  }
}

@keyframes mymoveone {
  from {
    transform: rotateX(0deg);
    width: 0px;
  }

  to {
    transform: rotateX(360deg);
    width: 320px;
  }
}

label {
  font-weight: 700;
  // color: #011e60 !important;/
  margin: 0;
  cursor: pointer;
}

.offer_tag_ba {
  position: absolute;
  width: auto;
  color: white;
  font-size: 20px;
  background-color: green;
  font-weight: 700;
  // top: 12px;
  padding-left: 32px;
  padding-right: 30px;
  border-radius: 10px;
  z-index: 1;
}

.main_content1 {
  font-size: 16px;
  // margin-top: 5px;
  // padding: 10px;

  @media screen and (max-width: 750px) {
    padding: 12px;
    margin-top: 21px;
  }

  @media screen and (max-width: 900px) {
    margin-top: 0px;
  }

  .count1 {
    display: flex;
    align-items: baseline;
    flex-direction: column;
  }
}

.fas {
  font-family: "Font Awesome 6 Free" !important;
}

.text_box{
  &:focus{
    outline: none;
    border: none;
  }
  >p{
    padding: 0;
    margin: 0;
  }
}

.seemore{
  color: rgb(87, 95, 113) !important;
  cursor: pointer !important; 
  font-size: 16px !important;
  font-weight: 100 !important;
}